import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { Colors } from "../../constants/Style";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    chip: {
      "&.MuiChip-root": {
        background: "#F6F8FA",
        borderRadius: "8px",
        transition: "0.2s",
        margin: "4px",
        maxWidth: "calc(100% - 8px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "&:focus": {
          background: "#F6F8FA",
        },
        "&:hover": {
          background: Colors.brand,
          "& .MuiChip-label": {
            color: "#fff",
          },
        },
      },
      "& .MuiChip-label": {
        textOverflow: "ellipsis",
        fontFamily: "Roboto",
        fontSize: "14px",
        lineHeight: "24px",
        color: "#414042",
        padding: "0 8px 0 12px",
        transition: "0.2s",
      },
      "& svg": {
        margin: "0 8px 0 0",
      },
      "& .MuiTouchRipple-root": {
        display: "none",
      },
    },
  })
);
