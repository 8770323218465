import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useAuth } from "../../state/Auth";
import { useStyles } from "./Login.style";

import LoginHeader from "../../components/LoginHeader";
import LoginCard from "../../components/LoginCard";
import LoginLoading from "../../components/LoginLoading";
import { validateEmail } from "../../utils/Common";
import LoginFooter from "../../components/LoginFooter";

const Login = () => {
  const classes = useStyles();
  const history = useHistory();

  const { isLoading, isLoggedIn } = useAuth();

  useEffect(() => {
    if (isLoggedIn && !isLoading) {
      history.push("/");
    }
  }, [isLoggedIn, isLoading, history]);

  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>();
  const [passwordError, setPasswordError] = useState<string>();
  const [password, setPassword] = useState<string>("");

  const handleChange = (e: React.FormEvent<HTMLInputElement>, type: string) => {
    setEmailError("");
    setPasswordError("");
    type === "email" && setEmail(e.currentTarget.value);
    type === "password" && setPassword(e.currentTarget.value);
  };

  const { login } = useAuth();

  const handleLogin = useCallback(() => {
    setEmailError("");
    setPasswordError("");
    if (login && email) {
      if (validateEmail(email)) {
        login(email || "", password || "").then((r) => {
          if (r.status === false) {
            setEmailError(" ");
            setPasswordError("Incorrect email or password");
          }
        });
      } else {
        setEmailError("Invalid email");
      }
    }
  }, [login, email, password]);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        handleLogin();
      }
    },
    [handleLogin]
  );

  return (
    <div className={classes.root}>
      <div className={classes.wrap}>
        {!isLoading ? (
          <>
            <LoginHeader />
            <LoginCard
              email={email}
              password={password}
              emailError={emailError}
              passwordError={passwordError}
              handleChange={handleChange}
              handleLogin={handleLogin}
              handleKeyPress={handleKeyPress}
            />
            <LoginFooter />
          </>
        ) : (
          <LoginLoading />
        )}
      </div>
    </div>
  );
};

export default Login;
