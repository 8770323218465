import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    troubleshootingMsg: {
      display: "inline-flex",
      alignItems: "center",
      fontFamily: "Roboto",
      fontSize: "14px",
      lineHeight: "16px",
      borderRadius: "8px",
      padding: "8px 30px 8px 20px",
      "& svg": {
        margin: "0 12px 0 0",
      },
      "& .MuiTypography-root": {
        fontFamily: "Roboto",
        width: "calc(100% - 36px)",
      },
      "& div": {
        display: "inline",
      },
      "& span": {
        fontFamily: "Roboto",
        disaplay: "inline-block",
        textDecoration: "underline",
        margin: "0 0 0 3px",
        cursor: "pointer",
      },
    },
    red: {
      color: "#F7204F",
      background: "#FDF7F8",
    },
    gray: {
      color: "#85868B",
      background: "#F6F8FA",
    },
  })
);
