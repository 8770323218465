import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { Colors } from "../../constants/Style";
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    button: {
      display: "inline-block",
      padding: "10px",
      cursor: "pointer",
      "& svg": {
        "& .stroke": {
          stroke: "#D9DEE3",
          transition: "0.2s",
        },
        "& .fill": {
          fill: "#D9DEE3",
          transition: "0.2s",
        },
      },
      "&:hover": {
        "& svg": {
          "& .stroke": {
            stroke: Colors.brand,
          },
          "& .fill": {
            fill: Colors.brand,
          },
        },
      },
    },
    open: {
      "& svg": {
        "& .stroke": {
          stroke: Colors.brand,
        },
        "& .fill": {
          fill: Colors.brand,
        },
      },
    },
    paper: {
      background: "#FFFFFF",
      border: "1px solid #F6F8FA",
      boxShadow: "0px 8px 19px -15px rgba(17, 41, 104, 0.3)",
      borderRadius: "16px",
      padding: "25px 40px 23px 31px",
      maxWidth: "368px",
      "& p": {
        lineHeight: "20px",
        color: "#414042",
      },
    },
  })
);
