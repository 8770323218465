import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { Colors } from "../../constants/Style";
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      "& .MuiBackdrop-root": {
        background: "rgba(0,0,0, 0.85)",
      },
      "& .MuiDialog-paperWidthSm": {
        borderRadius: "40px",
      },
    },
    modalBody: {
      position: "relative",
      background: "#FFFFFF",
      borderRadius: "40px",
      border: "none",
      width: "384px",
      "&.MuiDialogContent-root": {
        padding: "40px 48px 32px 50px",
      },
    },
    closeBtn: {
      position: "absolute",
      top: "10px",
      right: "10px",
      padding: "10px",
      "&:hover svg path": {
        stroke: Colors.brand,
      },
      "& svg": {
        width: "16px",
        height: "16spx",
        "& path": {
          transition: "0.2s",
        },
      },
    },
    modalHeader: {
      margin: "0 0 32px 0",
      "& p": {
        color: "#414042",
        margin: "0 0 5px 0",
        fontWeight: 700
      },
      "& b": {
        color: Colors.red,
      }
    },
    buttonsWrapper: {
      display: "flex",
      justifyContent: "space-between"
    },
    button: {
      textTransform: "none",
      display: "block",
      background: Colors.brand,
      boxShadow:
        "0px 16px 13px -15px rgba(83, 75, 158, 0.3), 0px 8px 22px -8px rgba(92, 79, 161, 0.45)",
      borderRadius: "20px",
      fontFamily: "Roboto",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "16px",
      color: "#FFFFFF",
      padding: "12px 18px",
      transition: "0.2s",
      minWidth: "144px",
      textAlign: "center",
      "&:hover": {
        background: "#3B3E96",
      },
    },
    secondaryButton: {
      textTransform: "none",
      borderRadius: "20px",
      fontFamily: "Roboto",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "16px",
    },
    deleteButton: {
      backgroundColor: Colors.red,
    }
  })
);
