import React from "react";

const Icon = (props) => {
  return (
    <svg
      width="152"
      height="20"
      viewBox="0 0 160 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.styleClass}
    >
      <path
        d="M84.2437 0.484935C87.0517 0.301955 89.7863 1.28339 91.7042 3.32188C91.7822 3.40505 92.1722 3.87687 92.2181 3.94038L91.539 4.50142C88.8365 1.10344 83.8996 0.312541 80.2764 2.80318C75.3884 6.16336 75.5627 13.3465 80.5991 16.4798C84.1764 18.7043 88.9222 17.868 91.5054 14.594L92.2303 15.1263L92.2012 15.1717C90.8431 16.8594 88.8717 18.0661 86.7137 18.4578C80.1647 19.6464 74.6053 13.9377 76.0429 7.50773C76.8887 3.72716 80.31 0.740502 84.2467 0.484935H84.2437Z"
        fill={props.fill}
      />
      <path
        d="M95.7373 16.8397C94.578 15.6556 93.9983 14.2099 93.9983 12.5041C93.9983 10.7983 94.578 9.32846 95.7373 8.14438C96.8966 6.9603 98.3816 6.36751 100.192 6.36751C102.003 6.36751 103.404 6.94367 104.597 8.09448C105.79 9.24528 106.387 10.7167 106.387 12.5041C106.387 14.2916 105.79 15.7585 104.597 16.9017C103.404 18.0449 101.936 18.6151 100.192 18.6151C98.4489 18.6151 96.8966 18.0223 95.7373 16.8382V16.8397ZM103.975 16.318C104.99 15.332 105.498 14.0617 105.498 12.5041C105.498 10.9465 104.99 9.6672 103.975 8.6661C102.959 7.66349 101.699 7.16294 100.192 7.16294C98.686 7.16294 97.3585 7.68013 96.3597 8.716C95.361 9.75188 94.8624 11.0146 94.8624 12.5041C94.8624 13.9937 95.358 15.2503 96.3475 16.2681C97.337 17.2873 98.6202 17.7954 100.194 17.7954C101.768 17.7954 102.961 17.3024 103.976 16.3165L103.975 16.318Z"
        fill={props.fill}
      />
      <path
        d="M108.907 6.45219H109.764V9.46607H109.788C110.224 8.48766 110.88 7.72247 111.752 7.17504C112.625 6.62761 113.624 6.35239 114.749 6.35239C116.059 6.35239 117.091 6.78035 117.847 7.63476C118.602 8.49068 118.98 9.70651 118.98 11.2838V18.5304H118.149V11.6074C118.149 10.0634 117.827 8.93528 117.18 8.21999C116.533 7.50622 115.681 7.14933 114.624 7.14933C113.163 7.14933 111.987 7.64383 111.099 8.63132C110.209 9.6188 109.764 10.9269 109.764 12.5525V18.5289H108.907V6.45068V6.45219Z"
        fill={props.fill}
      />
      <path
        d="M123.515 17.7833C123.111 17.2193 122.91 16.38 122.91 15.2685V7.25065H121.248V6.45371H122.91V2.14536L123.767 2.04556V6.45371H128.502V7.25065H123.767V14.895C123.767 15.9082 123.897 16.6461 124.157 17.1119C124.417 17.5762 124.941 17.809 125.731 17.809C126.621 17.809 127.444 17.552 128.199 17.0378L128.425 17.8347C127.586 18.3655 126.688 18.6317 125.731 18.6317C124.655 18.6317 123.917 18.3504 123.515 17.7848V17.7833Z"
        fill={props.fill}
      />
      <path
        d="M130.868 6.45219H131.724V9.29065H131.775C132.16 8.37726 132.72 7.65895 133.45 7.13724C134.179 6.61552 135.04 6.35239 136.031 6.35239C136.368 6.35239 136.619 6.36146 136.787 6.37658V7.29904C136.417 7.26577 136.166 7.24914 136.031 7.24914C134.722 7.24914 133.676 7.73456 132.896 8.70542C132.116 9.67627 131.724 10.9511 131.724 12.5268V18.5289H130.868V6.45068V6.45219Z"
        fill={props.fill}
      />
      <path
        d="M139.006 16.8397C137.847 15.6556 137.267 14.2099 137.267 12.5041C137.267 10.7983 137.847 9.32846 139.006 8.14438C140.165 6.9603 141.65 6.36751 143.461 6.36751C145.272 6.36751 146.673 6.94367 147.866 8.09448C149.059 9.24528 149.655 10.7167 149.655 12.5041C149.655 14.2916 149.059 15.7585 147.866 16.9017C146.673 18.0449 145.205 18.6151 143.461 18.6151C141.718 18.6151 140.165 18.0223 139.006 16.8382V16.8397ZM147.243 16.318C148.259 15.332 148.767 14.0617 148.767 12.5041C148.767 10.9465 148.259 9.6672 147.243 8.6661C146.228 7.66349 144.968 7.16294 143.461 7.16294C141.955 7.16294 140.627 7.68013 139.628 8.716C138.63 9.75188 138.131 11.0146 138.131 12.5041C138.131 13.9937 138.627 15.2503 139.616 16.2681C140.606 17.2873 141.889 17.7954 143.463 17.7954C145.037 17.7954 146.229 17.3024 147.245 16.3165L147.243 16.318Z"
        fill={props.fill}
      />
      <path
        d="M151.922 0.202148H152.778V18.5304H151.922V0.202148Z"
        fill={props.fill}
      />
      <path
        d="M1.98395 14.8874V8.13682H0.427002V6.39927H1.98395V2.27995L4.01655 2.05614V6.39927H8.08327V8.13682H4.01655V14.5895C4.01655 16.0291 4.36831 16.749 5.52302 16.749C6.25103 16.749 7.0035 16.4511 7.58162 16.129L8.13374 17.8907C7.45621 18.2627 6.60279 18.6347 5.17126 18.6347C2.98725 18.6347 1.98395 17.3191 1.98395 14.8874Z"
        fill={props.fill}
      />
      <path
        d="M20.86 11.2384V18.485H18.8274V11.8085C18.8274 9.07894 17.6222 8.11111 15.8405 8.11111C13.4056 8.11111 11.8747 9.69895 11.8747 12.4043V18.485H9.8421V0.218783H11.8747V8.38331H11.9252C12.9285 6.99357 14.5359 6.24956 16.4186 6.24956C19.0798 6.24956 20.8616 7.78901 20.8616 11.2384H20.86Z"
        fill={props.fill}
      />
      <path
        d="M33.7102 13.0743H24.2982C24.575 15.332 26.1809 16.7974 28.3893 16.7974C29.9203 16.7974 31.075 16.1017 31.8275 15.1596L33.2575 16.3755C32.2037 17.7153 30.5474 18.6332 28.3129 18.6332C24.8487 18.6332 22.238 16.1259 22.238 12.4527C22.238 8.77952 24.7738 6.24804 28.0865 6.24804C31.3992 6.24804 33.7591 8.77952 33.7591 12.2546C33.7591 12.5767 33.7087 12.9986 33.7087 13.0743H33.7102ZM24.3731 11.4123H31.7525C31.4268 9.42675 30.1467 8.11111 28.189 8.11111C26.2313 8.11111 24.7509 9.35265 24.3731 11.4123Z"
        fill={props.fill}
      />
      <path
        d="M54.1677 11.4123V18.485H52.1351V11.9582C52.1351 9.22865 51.2067 8.11111 49.2735 8.11111C47.3404 8.11111 45.8599 9.77456 45.8599 12.4784V18.485H43.8273V11.9325C43.8273 9.25284 42.824 8.11111 40.9153 8.11111C39.0066 8.11111 37.3762 9.77456 37.3762 12.4784V18.485H35.3681V6.39776H37.3762V8.48312H37.4007C38.404 7.04348 39.9365 6.24956 41.5179 6.24956C43.4006 6.24956 44.7556 7.01928 45.4087 8.63283H45.4332C46.412 7.09338 48.0179 6.24956 49.8761 6.24956C52.6627 6.24956 54.1677 7.8374 54.1677 11.4123Z"
        fill={props.fill}
      />
      <path
        d="M55.5227 12.4542C55.5227 8.97913 58.0325 6.24956 61.8484 6.24956C65.438 6.24956 68.1236 8.78103 68.1236 12.4542C68.1236 16.1274 65.438 18.6347 61.8484 18.6347C58.0325 18.6347 55.5227 15.9293 55.5227 12.4542ZM66.065 12.4542C66.065 9.87286 64.2083 8.11111 61.8484 8.11111C59.2882 8.11111 57.5813 10.0468 57.5813 12.4542C57.5813 14.8617 59.3126 16.7974 61.8484 16.7974C64.2083 16.7974 66.065 15.0099 66.065 12.4542Z"
        fill={props.fill}
      />
    </svg>
  );
};
export default Icon;
