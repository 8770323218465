import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputWrap: {
      position: "relative",
      width: "100%",
      margin: "0 0 16px 0",
    },

    input: {
      width: "100%",
      border: "1px solid #D9DEE3",
      borderRadius: "8px",
      padding: "16px 23px",
      fontSize: "14px",
      lineHeight: "16px",
      transition: "0.2s",
      "&:hover, &:focus": {
        border: "1px solid #7C64AC",
      },
    },

    inputErrorImg: {
      display: "none",
      position: "absolute",
      top: "13px",
      right: "13px",
      width: "24px",
      height: "24px",
    },

    errorMsg: {
      display: "none",
      fontFamily: "Roboto",
      fontSize: "12px",
      lineHeight: "14px",
      color: "#F7204F",
      margin: "5px 0 0 0",
      padding: "0 23px",
    },

    inputError: {
      border: "1px solid #F7204F",
      "& img": {
        display: "block",
      },
    },

    display: {
      display: "block",
    },
  })
);
