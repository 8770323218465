import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { Colors } from "../../constants/Style";
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordion: {
      width: "100%",
      boxShadow: "none",
      "& .MuiCollapse-wrapperInner": {
        overflow: "hidden",
      },
    },
    summary: {
      padding: "0",
      justifyContent: "flex-start",
      "& .MuiAccordionSummary-content": {
        disply: "initial",
        flexGrow: "initial",
        margin: "0",
        "& span": {
          color: Colors.brand,
          margin: "0 0 0 13px",
        },
      },
      "& .MuiAccordionSummary-expandIcon": {
        flex: "initial",
        color: Colors.brand,
      },
    },
    arrowHidden: {
      pointerEvents: "none",
      "& .MuiAccordionSummary-expandIcon": {
        display: "none",
      },
    },
    details: {
      padding: "0",
      flexWrap: "wrap",
      margin: "-4px",
    },
  })
);
