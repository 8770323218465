import React from "react";
import { useStyles } from "./styles";
import Pattern from "../../assets/icons/Pattern";

const LoginFooter = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Pattern />
    </footer>
  );
};

export default LoginFooter;
