import React from "react";
import clsx from "clsx";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Chip from "../Chip";
import { deviceType } from "../../types/Devices";

interface IProps {
  devices?: deviceType[];
  title?: string;
}

const DevicesChipList = (props: IProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { devices, title = t("button.set_offline_temperature_for") } = props;

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={clsx(
          classes.summary,
          devices?.length === 1 && classes.arrowHidden,
        )}
      >
        {devices?.length === 1 ? (
          <Typography variant="h2">{title} {devices[0].DeviceName}</Typography>
        ) : (
          <Typography variant="h2">
            {title}
            <Typography component="span" variant="h2">
              {devices?.length || 0} {t("common.devices")}
            </Typography>
          </Typography>
        )}
      </AccordionSummary>
      {devices?.length !== 1 && (
        <AccordionDetails className={classes.details}>
          {devices?.map((device, key) => (
            <Chip key={key} device={device} />
          ))}
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default DevicesChipList;
