import axios from "axios";
import { toast } from "react-toastify";
// import { appInsights } from "../AppInsights";
// import { SeverityLevel } from "@microsoft/applicationinsights-web";
import Cookies from "js-cookie";

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.response.use(
  (response) => {
    if (response.data === "Success") {
      toast("Success", {
        type: "success",
      });
    }
    return response;
  },
  (error) => {
    if (error.response?.status === 400) {
      toast(error.response?.data?.Message || "Bad request.", {
        type: "error",
      });
    }
    if (error.response?.status === 401) {
      toast("Unauthorized", {
        type: "error",
      });
      // appInsights.trackException({ error: new Error("Unauthorized"), severityLevel: SeverityLevel.Error });
      Cookies.remove("_token");
      Cookies.remove("themo-user");
      window.location.href = "/auth";
    }
    if (error.response?.data?.error === "username") {
      toast(error.response?.data?.error_description || "Unknown username.", {
        type: "error",
      });
      // appInsights.trackException({ error: new Error("Unknown username"), severityLevel: SeverityLevel.Error });
    }
    if (error.response?.data?.error === "password") {
      toast(error.response?.data?.error_description || "Incorrect password.", {
        type: "error",
      });
      // appInsights.trackException({ error: new Error("Incorrect password"), severityLevel: SeverityLevel.Error });
    }
    // if (error.response?.data?.Message) {
    //   if (!error.response.data.Message.includes("Limit")) {
    //     toast(`${error.response.data.Message}`, {
    //       type: "error",
    //     });
    //   }
    // } else {
    //   toast("Something went wrong! Check the console for more details.", {
    //     type: "error",
    //   });
    // }
    return Promise.reject(error);
  },
);

export const mutateData = (
  type: "patch" | "delete" | "post" | "put" | "get",
  url: string,
  data?: any,
  headers?: {
    "Content-Type"?: string;
    "client-id"?: string;
    "Access-Control-Allow-Origin"?: string;
    "Authorization"?: string;
    "Accept"?: string;
  },
  responseType?: any,
  params?: Record<string, any>,
) => {
  return instance({
    method: type,
    url: url,
    data,
    headers,
    responseType: responseType,
    params: params,
  });
};

const fetcher = (url: string) => instance.get(url).then((res) => res.data);

export const SWROptions = {
  fetcher,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};