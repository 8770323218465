import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Row from "../Row/Row";
import React, { useCallback, useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Colors } from "../../../../constants/Style";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import { formatHour, getHour } from "../../../../utils/Common";
import OutsideClickHandler from "react-outside-click-handler";
import { animationClasses } from "../../../../style/animationsCss";
import { useTranslation } from "react-i18next";


const useStyles = makeStyles(theme => ({
  label: {
    color: Colors.dark,
    fontWeight: 700,
    fontSize: "14px",
    fontHeight: "16px",
    marginRight: 14,
  },
  input: {
    width: 72,
    height: "40px",
    marginRight: 24,
    border: "1px solid #D9DEE3",
    borderRadius: "4px",
    "& input": {
      textAlign: "center",
    },
    "&:after": {
      border: "unset",
    },
    "&:before": {
      border: "unset",
    },
    "& input[type='time']::-webkit-calendar-picker-indicator": {
      background: "none",
      display: "none",
    },
    "& input[type=time]::-webkit-clear-button": {
      "&::-webkit-appearance": "none",
      "&::-moz-appearance": "none",
      "&::-ms-appearance": "none",
      appearance: "none",
    },
    "& input[type='time']::-webkit-datetime-edit-ampm-field": {
      display: "none",
    },
  },
  inputNumber: {
    paddingLeft: 7,
    "& input": {
      textAlign: "center",
    },
  },
  clockInput: {
    // paddingLeft: 20,
    // paddingRight: 11,
  },
  button: {
    width: 95,
    height: 40,
    marginRight: 24,
    textTransform: "none",
  },
  primary: {
    color: Colors.green,
    background: Colors.white,
    borderColor: Colors.green,
    "&:hover": {
      background: "#F5FFF8",
    }
  },
  secondary: {
    color: Colors.red,
    borderColor: Colors.red,
    "&:hover": {
      background: "#FDF7F8",
    },
  },
}));

interface iProps {
  day: number;
  hour: number;
  minHour: number;
  maxHour?: number;
  count?: number;
  value?: number;
  onChange: ({ day, hour, count, value, prevHour, prevCount }: { day: number, hour: number, count?: number, value: number, prevHour: number, prevCount?: number }) => void;
  onClose: () => void;
}

const EditRow = (props: iProps) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const {
    day, hour, minHour, maxHour, count = 1, onChange, value, onClose = () => null,
  } = props;

  const [wasChange, setWasChange] = useState<boolean>(false);

  const [from, setFrom] = useState<string>(`${formatHour(hour)}`);
  const [until, setUntil] = useState<string>(`${formatHour(hour + count)}`);

  const [clickOutside, setClickOutside] = useState<boolean>(false);

  useEffect(() => {
    if (clickOutside) {
      setTimeout(() => {
        setClickOutside(false);
      }, 1000);
    }
  }, [clickOutside]);

  useEffect(() => {
    setFrom(`${formatHour(hour)}`);
    setUntil(`${formatHour(hour + count)}`);
  }, [count, hour]);

  const [temperature, setTemperature] = useState<number | undefined>(value);

  const handleChangeFrom = useCallback((event: any) => {
    if (event.target.value < formatHour(minHour)) {
      setFrom(formatHour(minHour));
    } else if (event.target.value > formatHour(hour + count - 1)) {
      setFrom(formatHour(hour + count - 1));
    } else {
      setFrom(event.target.value);
    }
    setWasChange(true);
  }, [count, hour, minHour]);

  const handleChangeUntil = useCallback(() => {
    if (until === "00:00" && !maxHour) {
      setUntil("00:00");
    } else if (until < from) {
      setUntil(formatHour(hour + 1));
    } else if (maxHour && until > formatHour(maxHour)) {
      setUntil(formatHour(maxHour));
    }
    setWasChange(true);
  }, [from, until, hour, maxHour]);

  const handleChangeTemperature = useCallback(() => {
    if (temperature && temperature < 5) {
      setTemperature(5);
    }
    if (temperature && temperature > 41) {
      setTemperature(41);
    }
    setWasChange(true);
  }, [temperature]);

  const handleOnSave = useCallback(() => {
    if (!wasChange) {
      onClose();
    } else if (temperature) {
      let diff = getHour(until) - getHour(from);
      onChange({
        prevHour: hour,
        prevCount: count,
        count: diff < 1 ? diff + 24 : diff,
        hour: getHour(from),
        day: day,
        value: temperature,
      });
    }
  }, [wasChange, temperature, from, until, count, day, hour, onChange, onClose]);

  const handleOnDelete = useCallback(() => {
    let diff = getHour(until) - getHour(from);
    onChange({
      prevHour: hour,
      prevCount: count,
      count: diff < 1 ? diff + 24 : diff,
      hour: getHour(from),
      day: day,
      value: 0,
    });
  }, [from, until, count, day, hour, onChange]);

  let _animationClasses = animationClasses();

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setClickOutside(true);
      }}
    >

      <Row style={{
        backgroundColor: "#FFFFFF",
        zIndex: 1,
        height: 80,
        marginTop: -10,
        paddingBottom: 0,
        marginBottom: -8,
        display: "flex",
        alignItems: "center",
      }}>
        <InputLabel className={classes.label} htmlFor={"time_from"}>From</InputLabel>
        <Input
          className={clsx(classes.input, classes.clockInput)}
          id={"time_from"}
          type={"time"}
          onChange={handleChangeFrom}
          inputProps={{
            step: 3600000,
            min: `${formatHour(minHour)}`,
            max: `${formatHour(hour + count - 1)}`,
          }}
          value={from}
        />

        <InputLabel className={classes.label} htmlFor={"time_until"}>Until</InputLabel>
        <Input
          className={clsx(classes.input, classes.clockInput)}
          id={"time_until"}
          type={"time"}
          inputProps={{
            step: 3600000,
            min: `${formatHour(hour + 1)}`,
            ...maxHour && { max: `${formatHour(maxHour)}` },
          }}
          value={until}
          onChange={event => setUntil(event.target.value)}
          onBlur={handleChangeUntil}
        />

        <InputLabel className={classes.label} htmlFor={"temperature"}>Min temperature, °C</InputLabel>
        <Input
          className={clsx(classes.input, classes.inputNumber)}
          id={"temperature"}
          type={"number"}
          inputProps={{
            min: 5,
            max: 41,
          }}
          value={temperature || ""}
          onChange={event => setTemperature(Number(event.target.value))}
          onBlur={handleChangeTemperature}
        />

        <Button
          className={clsx(classes.button, classes.primary, {
            [_animationClasses.headShake]: clickOutside,
          })}
          variant={"outlined"}
          onClick={handleOnSave}
        >
          {t("button.save")}
        </Button>

        <Button
          className={clsx(classes.button, classes.secondary)}
          variant={"outlined"}
          onClick={handleOnDelete}
        >
          {t("button.clear")}
        </Button>
      </Row>
    </OutsideClickHandler>
  );
};

export default EditRow;