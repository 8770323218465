import React, { useCallback, useEffect } from "react";
import { useStyles } from "./ScheduleModal.style";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ScheduleDiagram from "../ScheduleDiagram/ScheduleDiagram";
import { Box, Button, } from "@material-ui/core";
import { useSchedule } from "../../state/Schedule";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useApp } from "../../views/App/AppProvider";
import DevicesChipList from "../DevicesChipList";
import CloseIcon from "../../assets/icons/Close";
import ConfirmationModal from "../ConfirmationModal";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Tooltip from "../Tooltip";
import cloneDeep from "lodash/cloneDeep";

const ScheduleModal = () => {

  const classes = useStyles();

  const {
    loading,
    hours,
    dragEl, setDragEl,
    open, setOpen,
    schedules,
    handleChangePoints,
    copyDay, setCopyDay,
    pastedDays, setPastedDays, addToPastedDays,
    completeCopy, cancelCopy,
    setDevices,
    postTemperatureSchedule,
    allowSubmit,
    openConfirmationDialog,
    setOpenConfirmationDialog,
    predefinedSchedules,
    selectedPredefinedScheduleName, setSelectedPredefinedScheduleName,
    name, setName, nameError,
    reset,
  } = useSchedule();

  const {
    selectedDevices: devices,
    handleSetIsLoading,
    openScheduleModal: openSchedule,
    scheduleName,
    scheduleSwitchMode
  } = useApp();

  useEffect(() => {
    setDevices(devices ? cloneDeep(devices) : []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices]);

  const { t } = useTranslation();

  useEffect(() => {
    handleSetIsLoading({ scope: "schedule", value: loading });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const handleCloseModal = useCallback(() => {
    if (allowSubmit) {
      setOpen(undefined);
      setCopyDay(undefined);
      setPastedDays([]);
      setOpenConfirmationDialog(true);
    } else {
      reset();
    }

  }, [setOpen, setCopyDay, setPastedDays, allowSubmit, setOpenConfirmationDialog, reset]);

  const handleCloseConfirmationModal = useCallback(() => {
    setOpenConfirmationDialog(false);
  }, [setOpenConfirmationDialog]);

  const handleMainClick = useCallback(() => {
    if (copyDay !== undefined) {
      completeCopy();
    } else {
      postTemperatureSchedule();
    }
  }, [postTemperatureSchedule, completeCopy, copyDay]);

  const handleClickConfirmationModal = useCallback(() => {
    setOpenConfirmationDialog(false);
    if (allowSubmit) {
      postTemperatureSchedule();
    }
  }, [allowSubmit, postTemperatureSchedule, setOpenConfirmationDialog]);

  const handleExitConfirmationModal = useCallback(() => {
    reset();
  }, [reset]);

  return (
    <Dialog
      open={openSchedule && ((!loading && scheduleSwitchMode) || !scheduleSwitchMode)}
      maxWidth={"lg"}
      className={classes.dialogWrapper}
    >
      <ConfirmationModal
        title={t("alert.are_you_sure_you_dont_want_to_save")}
        open={openConfirmationDialog}
        onClose={handleCloseConfirmationModal}
        onConfirm={handleExitConfirmationModal}
        confirmText={t("button.exit_without_saving")}
        cancelText={t("button.back_to_editing")}
      />
      <button
        className={classes.closeBtn}
        onClick={handleCloseModal}
      >
        <CloseIcon />
      </button>
      <DialogTitle>
        <DevicesChipList
          devices={devices}
          title={t("common.schedule_for")}
        />
      </DialogTitle>
      <DialogContent>
        {(!scheduleName || predefinedSchedules.length > 0) && (
          <div style={{
            paddingLeft: 20,
            paddingBottom: 24,
          }}>
            <Box display="flex" flexDirection="row" gridGap={24} width={700}>
              <TextField
                fullWidth
                id="schedule-name"
                label={t("table_options.name")}
                variant="outlined"
                size="small"
                value={name}
                error={nameError}
                helperText={nameError && t("message.uniq_schedule_name")}
                onChange={event => setName(event.target.value)}
                disabled={!!scheduleName}
              />
              {predefinedSchedules.length > 0 && (
                <Box display="flex" width="100%">
                  <TextField
                    size="small"
                    fullWidth
                    select
                    label={t("common.predefined_schedules")}
                    variant="outlined"
                    id="predefined-schedule"
                    value={selectedPredefinedScheduleName}
                    onChange={event => setSelectedPredefinedScheduleName(event.target.value)}
                  >
                    {predefinedSchedules.map((schedule, key) => (
                      <MenuItem key={key} value={schedule.Name}>{schedule.Name}</MenuItem>
                    ))}
                  </TextField>
                  <Tooltip text={t("info.premade_schedules")} />
                </Box>
              )}
            </Box>
          </div>
        )}
        <ScheduleDiagram
          hours={hours}
          dragEl={dragEl}
          setDragEl={setDragEl}
          open={open} setOpen={setOpen}
          schedules={schedules}
          handleChangePoints={handleChangePoints}
          copyDay={copyDay}
          setCopyDay={setCopyDay}
          pastedDays={pastedDays}
          addToPastedDays={addToPastedDays}
        />
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.button}
          onClick={() => {
            if (copyDay !== undefined) {
              cancelCopy();
            } else {
              handleCloseModal();
            }
          }}
        >
          {t("button.cancel")}
        </Button>
        <Button
          color={"primary"}
          variant={"contained"}
          className={clsx(classes.button, classes.mainButton)}
          disabled={!!open || (copyDay === undefined && !allowSubmit)}
          onClick={handleMainClick}
        >
          {copyDay !== undefined ? t("button.copy_completed") : t("button.set_schedule")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScheduleModal;