import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { Colors } from "../../constants/Style";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogWrapper: {
      position: "relative",
      "& .MuiDialog-paper": {
        borderRadius: "24px",
      },
      "& .MuiDialogTitle-root": {
        maxWidth: 1200,
        paddingTop: 47,
        paddingLeft: 56,
        paddingRight: 56,
      },
      "& .MuiDialogContent-root": {
        paddingLeft: 56,
        paddingRight: 64,
      },
      "& .MuiDialogActions-root": {
        padding: "16px 64px",
        borderTop: "1px solid #D9DEE3",
        marginTop: 20,
      },
    },
    button: {
      textTransform: "none",
      borderRadius: "20px",
    },
    mainButton: {
      width: "176px",
    },
    closeBtn: {
      zIndex: 100,
      position: "absolute",
      top: "22px",
      right: "22px",
      padding: "10px",
      "&:hover svg path": {
        stroke: Colors.brand,
      },
      "& svg": {
        width: "24px",
        height: "24px",
        "& path": {
          transition: "0.2s",
        },
      },
    },
  }),
);
