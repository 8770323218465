import React from "react";

const Icon = (props) => {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.65083 1.69134C8.80216 1.69134 10.7628 2.48455 12.2664 3.78521L13.3108 2.6307C11.53 1.08274 9.2043 0 6.65083 0C4.10092 0 1.77877 1.07967 0 2.62383L1.04209 3.77951C2.54459 2.48031 4.50173 1.69134 6.65083 1.69134Z"
        fill={props.fill}
      />
      <path
        d="M6.65294 5.02272C7.94202 5.02272 9.12062 5.49101 10.0295 6.26389L11.1332 5.04201C9.93184 4.00794 8.36797 3.3324 6.65294 3.3324C4.94028 3.3324 3.37862 4.00633 2.17725 5.0366L3.27968 6.25965C4.18896 5.49013 5.36534 5.02272 6.65294 5.02272Z"
        fill={props.fill}
      />
      <path
        d="M8.92588 7.48593C8.31092 6.97423 7.51877 6.62053 6.65431 6.62053C5.79014 6.62053 4.99918 6.97321 4.38525 7.48272L6.65431 10L8.92588 7.48593Z"
        fill={props.fill}
      />
    </svg>
  );
};
export default Icon;
