import axios from "axios";

export const apiSendEmail = (deviceId: number | string, message?: string, fromName?: string) => {

  const data = {
    service_id: process.env.REACT_APP_EMAILJS_SERVICE_ID,
    template_id: process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
    user_id: process.env.REACT_APP_EMAILJS_USER_ID,
    accessToken: process.env.REACT_APP_EMAILJS_TOKEN,
    template_params: {
      from_name: fromName || "",
      DeviceID: deviceId,
      message: message || "This is a test message!",
    },
  };

  return axios.post("https://api.emailjs.com/api/v1.0/email/send", JSON.stringify(data), {
    headers: {
      "Content-Type": "application/json",
    },
  });
};