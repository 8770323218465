import React, { useMemo } from "react";
import { useStyles } from "./ScheduleDiagram.style";
import Row from "./elements/Row/Row";
import Cell from "./elements/Cell/Cell";
import StyledDay from "./elements/Day/Day";
import ScheduleBox from "./elements/ScheduleBox/ScheduleBox";
import EditRow from "./elements/EditRow/EditRow";
import { formatHour } from "../../utils/Common";
import { dragElType, openType, setpointsInterface } from "../../types/Schedule";
import { days } from "../../constants/days";
import { generateGradient, getColor } from "../../utils/Colors";
import { getMaxHour, getMinHour } from "../../utils/Schedule";

interface iProps {
  hours: number[];
  dragEl?: dragElType;
  setDragEl: (params: dragElType) => void;
  open?: openType,
  setOpen: (params?: openType) => void;
  schedules: setpointsInterface;
  handleChangePoints: ({
                         day,
                         hour,
                         count,
                         value,
                         prevHour,
                         prevCount,
                       }: { day: number, hour: number, count?: number, value: number, prevHour: number, prevCount?: number }) => void;
  copyDay?: number,
  setCopyDay: (day: number) => void;
  pastedDays?: number[];
  addToPastedDays: (day: number) => void;
}

const ScheduleDiagram = (props: iProps) => {

  const {
    hours,
    dragEl, setDragEl,
    open, setOpen,
    schedules,
    handleChangePoints,
    copyDay,
    setCopyDay,
    pastedDays,
    addToPastedDays,
  } = props;

  const rowsMap = useMemo(
    () => (
      days.map((day, _key) => {

        let key = _key;


        // change sunday with monday order
        if (key < 6) {
          key = key + 1;
        } else {
          key = 0;
        }

        return (
          <React.Fragment key={key}>
            <Row>
              {schedules[key].map((schedule, index) => {
                return schedule && schedule.Value && schedule.Count ? (
                  <ScheduleBox
                    style={{
                      background: generateGradient(schedules[key], schedule, index),
                    }}
                    arrowStyle={{
                      color: getColor(schedule.Value),
                    }}
                    key={index}
                    value={schedule.Value}
                    hour={index}
                    day={key}
                    count={schedule.Count}
                    open={!!open && open.day === key && open.hour === index}
                    onClick={() => {
                      if (!open && copyDay === undefined) {
                        setOpen({
                          day: key,
                          hour: index,
                          count: schedule.Count,
                          value: schedule.Value,
                        });
                      }
                    }}
                    onDragStart={(pos: number) => {
                      setDragEl({
                        position: pos,
                        day: key,
                        hour: index,
                        value: schedule.Value,
                        count: schedule.Count || 1,
                      });
                    }}
                  />
                ) : (!schedule || schedule?.Count !== -1) && (
                  <Cell
                    key={index}
                    hour={index}
                    open={!!open && open.day === key && open.hour === index}
                    onClick={() => {
                      if (copyDay === undefined && !open) {
                        setOpen({
                          day: key,
                          hour: index,
                          count: 1,
                          value: undefined,
                        });
                      }
                    }}
                    onDrop={() => {
                      if (dragEl) {
                        handleChangePoints({
                          day: dragEl.day,
                          prevHour: dragEl.hour,
                          hour: index - dragEl.position,
                          value: dragEl.value,
                          count: dragEl.count,
                          prevCount: dragEl.count,
                        });
                      }
                    }}
                  />
                );
              })}
            </Row>
            {(!!open && open.day === key) && (
              <EditRow
                minHour={
                  getMinHour(schedules[key], {
                    Day: open.day,
                    Hour: open.hour,
                    Value: open.value || 0,
                    Count: open.count,
                  })
                }
                maxHour={
                  getMaxHour(schedules[key], {
                    Day: open.day,
                    Hour: open.hour,
                    Value: open.value || 0,
                    Count: open.count,
                  })
                }
                day={open.day}
                hour={open.hour}
                value={open.value}
                count={open.count}
                onChange={handleChangePoints}
                onClose={() => setOpen(undefined)}
              />
            )}
          </React.Fragment>
        );
      })
    ),
    [schedules, open, setOpen, dragEl, copyDay, handleChangePoints, setDragEl],
  );

  const daysMap = useMemo(
    () => days.map((day, key) => {

      // change sunday with monday order
      if (key < 6) {
        key = key + 1;
      } else {
        key = 0;
      }

      return (
        <StyledDay
          dayId={key}
          key={key}
          open={!!open && open.day === key}
          copy={() => {
            setCopyDay(key);
          }}
          paste={() => {
            console.log("paste", copyDay, " to ", key);
            addToPastedDays(key);
          }}
          copied={copyDay === key}
          pasted={pastedDays?.includes(key)}
          allowPaste={copyDay !== undefined && copyDay !== key}
          disabled={!!open}
        />
      );
    }),
    [open, copyDay, pastedDays, addToPastedDays, setCopyDay],
  );

  const hoursMap = useMemo(
    () => [...hours, 24].map((hour, key) => (
      <div key={key}>
        {formatHour(hour)}
      </div>
    )),
    [hours],
  );

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.daysContainer}>
        {daysMap}
      </div>
      <div className={classes.rowsContainer}>
        {rowsMap}
      </div>
      <div className={classes.hoursContainer}>
        {hoursMap}
      </div>
    </div>
  );
};

export default ScheduleDiagram;