import React from "react";
import { useStyles } from "./styles";
import clsx from "clsx";

import ErrorIcon from "../../assets/icons/errorIcon.svg";

interface IProps {
  name: string;
  type: string;
  placeholder: string;
  autoFocus?: boolean;
  handleChange: (e: React.FormEvent<HTMLInputElement>, type: string) => void;
  value: string;
  errorMsg: string;
  error?: string;
}

const LoginInput = (props: IProps) => {
  const classes = useStyles();
  const { name, type, placeholder, autoFocus, value, errorMsg, error } = props;

  return (
    <div className={classes.inputWrap}>
      <input
        name={name}
        className={clsx(classes.input, error && classes.inputError)}
        type={type}
        placeholder={placeholder}
        required
        onChange={(e) => props.handleChange(e, name)}
        autoFocus={autoFocus}
        value={value}
      />
      <img
        className={clsx(classes.inputErrorImg, {
          [classes.display]: !!error,
        })}
        src={ErrorIcon}
        alt=""
      />
      <div
        className={clsx(classes.errorMsg, {
          [classes.display]: !!error,
        })}
      >
        {errorMsg}
      </div>
    </div>
  );
};

export default LoginInput;
