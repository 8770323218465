import tinygradient from "tinygradient";

import { schedulePointType } from "../types/Schedule";

export const getColor = (value: number) => {
  if (value < 13) {
    return "#79A0C5";
  }
  if (value < 15) {
    return "#719BA9";
  }
  if (value < 17) {
    return "#71A2A9";
  }
  if (value < 19) {
    return "#71A99C";
  }
  if (value < 21) {
    return "#71A98E";
  }
  if (value < 23) {
    return "#71A97D";
  }
  if (value < 25) {
    return "#72A971";
  }
  if (value < 27) {
    return "#88B367";
  }
  if (value < 29) {
    return "#AABD5F";
  }
  if (value < 31) {
    return "#C0C34A";
  }
  if (value < 33) {
    return "#C2B331";
  }
  if (value < 35) {
    return "#CEB532";
  }
  if (value < 37) {
    return "#D7A830";
  }
  if (value < 39) {
    return "#D78A30";
  }
  return "#D76230";
};

export const generateGradient = (
  schedules: schedulePointType[], schedule: schedulePointType, index: number
) => {

  const prevEl = schedules[index - 1] ? schedules[index - 1] : schedule;
  const nextEl = schedules[index + 1] ? schedules[index + (schedules[index]?.Count ?? 0)] : schedule;

    return tinygradient(
      [
        getColor(Math.max(prevEl!.Value, schedule!.Value)), 
        getColor(schedule!.Value),
        getColor(schedule!.Value),
        getColor(Math.max(nextEl?.Value ?? schedule!.Value, schedule!.Value)),
      ]
    ).css();
}