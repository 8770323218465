import { deviceType, problemType } from "../types/Devices";
import { TFunction } from "i18next";


export const getDeviceProblem = (
  { device, t }: { device: deviceType, t: TFunction },
): problemType | undefined => {

  if (!device) {
    return;
  }

  // if ((!device.limitTemperature || device.limitTemperature === -1) && device.State?.Mode === "SLS") {
  //   return {
  //     level: "non-urgent",
  //     message: t("message.limit_not_set"),
  //   };
  // }

  // if ((!device.last24Consumption || device.last24Consumption === -1) && device.State?.Mode === "SLS") {
  //   return {
  //     level: "non-urgent",
  //     message: t("message.unavailable_heating_history"),
  //   };
  // }

  const info: number = device.State?.Info || 0;
  const limit: number = device.limitTemperature || 0;
  const last24Consumption: number = device.last24Consumption || 0;
  const maximumPowerConsumption: number = device.State?.MP || 0;
  const manualTemperature: number = device.State?.MT || 0;
  const mode = device.State?.Mode;
  const online = device.State?.Online;
  const lastDatapointDate = device.lastDatapointDate || 0;

  const isFloor = device.State?.DeviceParameters?.IsFloor; // DevicetypeID = 4 === isFloor
  const isAir = device.State?.DeviceParameters?.IsAir; // DevicetypeID = 7 === isAir

  if (mode === "SLS" && info < 50 && device.State?.Power === true && info > limit + 2 && limit > 0) {
    return {
      level: "urgent",
      message: t("message.too_high_temperature"),
      link: "https://support.themo.io/support/solutions/articles/36000310383-temperature-values-are-too-high-or-low-",
    };
  }

  if (mode === "SLS" && last24Consumption > maximumPowerConsumption * 24 * 0.95) {
    return {
      level: "urgent",
      message: t("message.too_much_energy"),
      link: "https://support.themo.io/support/solutions/articles/36000310382-device-is-consuming-too-much-energy-",
    };
  }

    if (mode === "SLS" && last24Consumption > maximumPowerConsumption * 24 * 0.4) {
    return {
      level: "non-urgent",
      message: t("message.too_much_energy"),
      link: "https://support.themo.io/support/solutions/articles/36000310382-device-is-consuming-too-much-energy-",
    };
  }

  if (isFloor && mode === "SLS" && info > 33 && info > limit) {
    return {
      level: "urgent",
      message: t("message.too_high_floor_temperature"),
      link: "https://support.themo.io/support/solutions/articles/36000310381-room-or-floor-temperature-is-too-high-",
    };
  }

  if (isAir && mode === "SLS" && info > 27 && info > limit) {
    return {
      level: "urgent",
      message: t("message.too_high_room_temperature"),
      link: "https://support.themo.io/support/solutions/articles/36000310381-room-or-floor-temperature-is-too-high-",
    };
  }

  if (isFloor && mode === "Manual" && info > manualTemperature + 2 && info > 33) {
    return {
      level: "urgent",
      message: t("message.too_high_floor_temperature"),
      link: "https://support.themo.io/support/solutions/articles/36000310381-room-or-floor-temperature-is-too-high-",
    };
  }

  if (isAir && mode === "Manual" && info > manualTemperature + 2 && info > 27) {
    return {
      level: "urgent",
      message: t("message.too_high_room_temperature"),
      link: "https://support.themo.io/support/solutions/articles/36000310381-room-or-floor-temperature-is-too-high-",
    };
  }

  if (mode === "Manual" && info < manualTemperature - 4) {
    return {
      level: "non-urgent",
      message: t("message.too_low_temperature"),
      link: "https://support.themo.io/support/solutions/articles/36000310382-device-is-consuming-too-much-energy-",
    };
  }

  if (!online && (lastDatapointDate + 24 * 60 * 60) < new Date().getTime()) {
    return {
      level: "non-urgent",
      message: t("message.internet_trouble"),
      link: "https://support.themo.io/support/solutions/articles/36000310384-device-has-trouble-connecting-to-the-internet-",
    };
  }

  if (info > 50) {
    return {
      level: "non-urgent",
      message: t("message.not_correct_temperature_sensor"),
      link: "https://support.themo.io/support/solutions/articles/36000310383-temperature-values-are-too-high-or-low-",
    };
  }

  if (info < -5) {
    return {
      level: "urgent",
      message: t("message.extremely_low_temperature"),
      link: "https://support.themo.io/support/solutions/articles/36000310383-temperature-values-are-too-high-or-low-",
    };
  }


};