import { mutateData } from "./api";
import { Endpoints } from "./endpoints";
import { deviceLocationType } from "../types/Devices";

export const apiDeviceChangeManualTemperature = ({
  deviceId,
  token,
  value,
}: {
  deviceId: number | string;
  token: string | undefined;
  value: number;
}) => {
  return mutateData(
    "post",
    `${Endpoints.devices}/${deviceId}/Message/Temperature`,
    {
      CMT: value.toString(),
    },
    {
      "Content-Type": "application/json",
      "client-id": "ThemoPortal",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    }
  );
};

export const apiDeviceChangeOfflineTemperature = ({
  deviceId,
  token,
  value,
}: {
  deviceId: number | string;
  token: string | undefined;
  value: number;
}) => {
  return mutateData(
    "post",
    `${Endpoints.devices}/${deviceId}/Message/FrostProtection`,
    {
      CFT: value,
    },
    {
      "Content-Type": "application/json",
      "client-id": "ThemoPortal",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    }
  );
};

export const apiDeviceChangeModeState = ({
  deviceId,
  mode,
  token,
}: {
  deviceId: number | string;
  mode: "Off" | "SLS" | "Manual" | "Mode";
  token: string | undefined;
}) => {
  return mutateData(
    "post",
    `${Endpoints.devices}/${deviceId}/Message/Mode`,
    {
      CMode: mode,
    },
    {
      "Content-Type": "application/json",
      "client-id": "ThemoPortal",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    }
  );
};

export const apiDeviceChangeLockState = ({
  deviceId,
  value,
  token,
}: {
  deviceId: number | string;
  value: 0 | 1;
  token: string | undefined;
}) => {
  return mutateData(
    "post",
    `${Endpoints.devices}/${deviceId}/Message/Lock`,
    {
      CLock: value.toString(),
    },
    {
      "Content-Type": "application/json",
      "client-id": "ThemoPortal",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    }
  );
};

export const apiDeviceChangeLightsState = ({
  deviceId,
  value,
  token,
}: {
  deviceId: number | string;
  value: 0 | 1;
  token: string | undefined;
}) => {
  return mutateData(
    "post",
    `${Endpoints.devices}/${deviceId}/Message/Lights`,
    {
      CLights: value,
    },
    {
      "Content-Type": "application/json",
      "client-id": "ThemoPortal",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    }
  );
};

export const apiDeviceChangeFrostProtectionState = ({
  deviceId,
  value,
  token,
}: {
  deviceId: number | string;
  value: 0 | 1;
  token: string | undefined;
}) => {
  return mutateData(
    "post",
    `${Endpoints.devices}/${deviceId}/Message/FrostProtectionState`,
    {
      CFTS: value,
    },
    {
      "Content-Type": "application/json",
      "client-id": "ThemoPortal",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    }
  );
};

export const apiDeviceHistoryGet1Day = ({
  deviceId,
  token,
}: {
  deviceId: number | string;
  token: string | undefined;
}) => {
  const today = new Date();
  const toDate = today.toISOString();
  const fromDate = new Date(
    new Date().setDate(today.getDate() - 1)
  ).toISOString();

  return mutateData(
    "get",
    `${Endpoints.devices}/${deviceId}/data?from=${fromDate}&to=${toDate}`,
    {},
    {
      "Content-Type": "application/json",
      "client-id": "ThemoPortal",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    }
  );
};

export const apiDeviceGetLimitTemperature = ({
  deviceId,
  token,
}: {
  deviceId: number | string;
  token: string | undefined;
}) => {
  return mutateData(
    "get",
    `${Endpoints.devices}/${deviceId}/schedules/limittemperature?api-version=2.0`,
    {},
    {
      "Content-Type": "application/json",
      "client-id": "ThemoPortal",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    }
  );
};

export const apiDeviceGetPriceSwitch = ({
  deviceId,
  token,
}: {
  deviceId: number | string;
  token: string | undefined;
}) => {
  return mutateData(
    "get",
    `${Endpoints.devices}/${deviceId}/schedules/priceswitch?api-version=2.0`,
    {},
    {
      "Content-Type": "application/json",
      "client-id": "ThemoPortal",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    }
  );
}

export const apiDeviceChangePriceSwitch = ({
  deviceId,
  token,
  OnHours,
  OffHours,
}: {
  deviceId: number | string;
  token: string | undefined;
  OnHours: number;
  OffHours: number;
}) => {
  return mutateData(
    "put",
    `${Endpoints.devices}/${deviceId}/schedules/priceswitch?api-version=2.0`,
    {
      OnHours,
      OffHours,
    },
    {
      "Content-Type": "application/json",
      "client-id": "ThemoPortal",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    }
  );
}

export const apiDeviceChangeGroup = ({
  deviceId,
  token,
  deviceName,
  groupName,
  deviceLocation,
  device,
}: {
  deviceId: number | string;
  token: string | undefined;
  deviceName: string;
  groupName: string;
  deviceLocation?: deviceLocationType;
  device?: any;
}) => {
  return mutateData(
    "put",
    `${Endpoints.devices}/${deviceId}`,
    {
      ...device,
      EnvironmentName: groupName,
    },
    {
      "Content-Type": "application/json",
      "client-id": "ThemoPortal",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    }
  );
}

export const apiDeviceChangeTimer = ({
  deviceId,
  token,
  value
}: {
  deviceId: number | string;
  token: string | undefined;
  value: number;
}) => {
  return mutateData(
    "post",
    `${Endpoints.devices}/${deviceId}/Message/Timer`,
    {
      "CManTimer": value,
    },
    {
      "Content-Type": "application/json",
      // "client-id": "ThemoPortal",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    }
  );
}
