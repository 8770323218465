import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { Colors } from "../../constants/Style";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    wrap: {
      width: "1200px",
      margin: "0 auto",
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("md")]: {
        padding: "0 15px",
      },
    },
    header: {
      width: "100vw",
      position: "fixed",
      padding: "16px 0",
      transition: "0.2s",
      borderBottom: "1px solid transparent",
      zIndex: 2,
    },
    white: {
      background: "#fff",
    },
    transparent: {
      background: "transparent",
    },
    headerFixed: {
      background: "#fff",
      borderBottom: "1px solid #D9DEE3",
    },
    col: {
      display: "flex",
      alignItems: "center",
    },
    logo: {
      position: "relative",
      margin: "0 45px 0 0",
      "& svg": {
        width: "176px",
      },
      "&:hover": {
        "& span": {
          visibility: "visible",
          transitionDelay: "2s",
          opacity: 1,
          transition: "opacity 1s",
        },
      },
    },
    logoDisabled: {
      opacity: 0.5,
      textDecoration: "none",
      "&:hover": {
        "& span": {
          visibility: "hidden",
        },
      },
    },
    logoTooltip: {
      fontSize: "10px",
      // whiteSpace: "nowrap",
      visibility: "hidden",
      width: "120px",
      boxShadow: "0px 12px 16px rgb(92 79 161 / 50%)",
      textAlign: "center",
      padding: "5px 10px",
      borderRadius: "6px",
      top: "calc(100% + 10px)",
      left: "50%",
      marginLeft:
        "-60px" /* Use half of the width (120/2 = 60), to center the tooltip */,

      /* Position the tooltip text - see examples below! */
      position: "absolute",
      zIndex: 1,
      opacity: 0,
      transition: "opacity 0s",
      // transitionDelay: "1.5s",
      transitionTimingFunction: "ease-in",

      // color: "#fff",
      color: "#111",

      background: Colors.white,
      // background: "inherit",

      // mixBlendMode: "difference",
    },
    logoTooltipPrimary: {
      color: Colors.white,
      background: "transparent",
      "& svg": {
        "& path": {
          fill: Colors.white,
        },
      },
    },
    link: {
      fontFamily: "Roboto",
      fontSize: "14px",
      lineHeight: "16px",
      color: "#FFFFFF",
      textDecoration: "none",
      padding: "8px 16px",
      borderRadius: "16px",
      transition: "0.2s",
      "& span": {
        fontWeight: "700",
      },
      "&:hover": {
        background: "rgba(255, 255, 255, 0.1)",
      },
    },
    linkBlack: {
      color: "#414042",
      "&:hover": {
        color: Colors.brand,
      },
    },
  })
);
