import {
  headShake,
} from 'react-animations';

import {makeStyles} from "@material-ui/core/styles";

export const animationClasses = makeStyles((theme) => ({
  '@keyframes headShake': headShake,
  headShake: {
    animationName: '$headShake',
    animationDuration: '1s',
  },
}))