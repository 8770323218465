import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { useStyles } from "./styles";
import CloseIcon from "../../assets/icons/Close";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import clsx from "clsx";

interface iProps {
  title?: string;
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
  confirmText?: string;
  cancelText?: string;
  isDelete?: boolean;
}

const ConfirmationModal = (props: iProps) => {

  const {
    onClose, onConfirm, open = false,
    confirmText, cancelText, title,
    isDelete,
  } = props;

  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <Dialog
      className={classes.modal}
      open={open}
      PaperProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          boxShadow: 'none',
        },
      }}
      onClose={onClose}
    >
      <DialogContent className={classes.modalBody}>
        <button className={classes.closeBtn} onClick={onClose}>
          <CloseIcon width={16} height={16} />
        </button>
        <div className={classes.modalHeader}>
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{ __html: title || "Are you sure?" }}
          >
            {/*{title || "Are you sure?"}*/}
          </Typography>
        </div>
        <div className={classes.buttonsWrapper}>
          <Button
            className={classes.secondaryButton}
            onClick={onClose}
          >
            {cancelText || t("button.cancel")}
          </Button>
          <Button
            className={clsx(classes.button, { [classes.deleteButton]: isDelete })}
            onClick={onConfirm}
          >
            {confirmText || t("button.confirm")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );

};

export default ConfirmationModal;