import React from "react";
import { useStyles } from "./styles";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import Link from "@material-ui/core/Link";
import { useLang } from "../../state/Lang";

interface IProps {
  color: string;
}

const ChangeLang = (props: IProps) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { color } = props;

  const { lang = "en", changeLang } = useLang();

  return (
    <div className={classes.langList}>
      <Link
        underline={"none"}
        component="a"
        className={clsx(classes.langLink, color, {
          [classes.activeLangLink]: lang === "en",
        })}
        onClick={() => {
          changeLang("en");
        }}
      >
        {t("langs.en")}
      </Link>
      <Link
        underline={"none"}
        component="a"
        className={clsx(classes.langLink, color, {
          [classes.activeLangLink]: lang === "fi",
        })}
        onClick={() => {
          changeLang("fi");
        }}
      >
        {t("langs.fi")}
      </Link>
      <Link
        underline={"none"}
        component="a"
        className={clsx(classes.langLink, color, {
          [classes.activeLangLink]: lang === "ee",
        })}
        onClick={() => {
          changeLang("ee");
        }}
      >
        {t("langs.ee")}
      </Link>
    </div>
  );
};

export default ChangeLang;
