import { mutateData } from "./api";
import { Endpoints } from "./endpoints";
import { pointType, scheduleInterface } from "../types/Schedule";


export const defaultScheduleName = "Default";
export const apiVers = "2.0";

export const apiGetTemperatureSchedules = (
  {
    deviceId,
    token,
    scheduleName,
    apiVersion = apiVers,
  }: {
    deviceId: number | string;
    token: string | undefined;
    scheduleName?: string;
    apiVersion?: number | string;
  },
) => {
  return mutateData(
    "get",
    `${Endpoints.devices}/${deviceId}/schedules/temperature`,
    {},
    {
      "Content-Type": "application/json",
      "client-id": "ThemoPortal",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
    undefined,
    {
      "api-version": apiVersion,
      ...(scheduleName && { scheduleName: scheduleName }),
    },
  );
};

export const apiGetPredefinedTemperatureSchedules = (
  {
    deviceId, token, apiVersion = apiVers,
  }: {
    deviceId: number | string;
    token: string | undefined;
    apiVersion?: number | string;
  },
) => {
  return mutateData(
    "get",
    `${Endpoints.devices}/${deviceId}/schedules/default/temperature?api-version=${apiVersion}`,
    {},
    {
      "Content-Type": "application/json",
      "client-id": "ThemoPortal",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  );
};

export const apiPostTemperatureSchedule = (
  {
    deviceId,
    token,
    scheduleName = defaultScheduleName,
    apiVersion = apiVers,
    Setpoints,
  }: {
    deviceId: number | string;
    token: string | undefined;
    scheduleName?: string;
    apiVersion?: number | string;
    Setpoints: pointType[];
  },
) => {
  return mutateData(
    "post",
    `${Endpoints.devices}/${deviceId}/schedules/temperature?api-version=${apiVersion}`,
    {
      Name: scheduleName,
      Setpoints: Setpoints || [],
      Active: true,
    },
    {
      "Content-Type": "application/json",
      "client-id": "ThemoPortal",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  );
};

export const apiUpdateTemperatureSchedule = (
  {
    deviceId,
    token,
    scheduleName = defaultScheduleName,
    apiVersion = apiVers,
    schedule,
  }: {
    deviceId: number | string;
    token: string | undefined;
    scheduleName?: string;
    apiVersion?: number | string;
    schedule: scheduleInterface;
  },
) => {
  return mutateData(
    "put",
    `${Endpoints.devices}/${deviceId}/schedules/temperature?scheduleName=${scheduleName}&api-version=${apiVersion}`,
    {
      ...schedule,
      Active: true,
    },
    {
      "Content-Type": "application/json",
      "client-id": "ThemoPortal",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  );
};

export const apiSwitchTemperatureSchedule = (
  {
    deviceId,
    token,
    scheduleName = defaultScheduleName,
    apiVersion = apiVers,
  }: {
    deviceId: number | string;
    token: string | undefined;
    scheduleName?: string;
    apiVersion?: number | string;
  },
) => {
  return mutateData(
    "put",
    `${Endpoints.devices}/${deviceId}/schedules/temperature/switch?scheduleName=${scheduleName}&api-version=${apiVersion}`,
    undefined,
    {
      "Content-Type": "application/json",
      "client-id": "ThemoPortal",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  );
};

export const apiDeleteTemperatureSchedule = (
  {
    deviceId,
    token,
    scheduleName = defaultScheduleName,
    apiVersion = apiVers,
  }: {
    deviceId: number | string;
    token: string | undefined;
    scheduleName?: string;
    apiVersion?: number | string;
  },
) => {
  return mutateData(
    "delete",
    `${Endpoints.devices}/${deviceId}/schedules/temperature?scheduleName=${scheduleName}&api-version=${apiVersion}`,
    undefined,
    {
      "Content-Type": "application/json",
      "client-id": "ThemoPortal",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  );
};

export const apiPostElectricityProfile = (
  {
    deviceId,
    token,
    apiVersion = apiVers,
    data = {},
  }: {
    deviceId: number | string;
    token: string | undefined;
    apiVersion?: number | string;
    data: Record<string, any>;
  },
) => {
  return mutateData(
    "post",
    `${Endpoints.devices}/${deviceId}/ElectricityProfile?api-version=${apiVersion}`,
    {
      ...data,
    },
    {
      "Content-Type": "application/json",
      "client-id": "ThemoPortal",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  );
};