import React from "react";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { days } from "../../../../constants/days";
import { useTranslation } from "react-i18next";
import { ReactComponent as Check } from "../../../../assets/icons/check.svg";
import { Colors } from "../../../../constants/Style";

const useStyles = makeStyles(theme => ({
  root: {
    width: "56px",
    marginBottom: "32px",
  },
  open: {
    marginBottom: 96,
  },
  button: {
    fontSize: "12px",
    lineHeight: "14px",
    color: Colors.brand,
    minWidth: "unset",
    marginLeft: "-5px",
    textTransform: "unset",
  },
  label: {
    fontSize: "12px",
    paddingTop: 6,
    position: "relative",
    color: "#828282",
    display: "flex",
    alignItems: "center",
  },
  checkIcon: {
    position: "absolute",
    width: "10px",
    left: -17,
  },
  underline: {
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "underline",
    }
  }
}));

interface iProps {
  dayId: number;
  open: boolean;
  copied?: boolean;
  pasted?: boolean;
  allowPaste?: boolean;
  copy: () => void;
  paste: () => void;
  disabled?: boolean;
}

const StyledDay = (props: iProps) => {
  const { t } = useTranslation();
  const {
    dayId,
    open,
    copied,
    pasted,
    allowPaste,
    paste,
    copy,
    disabled = false,
  } = props;
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, {
      [classes.open]: open,
    })}>
      <Typography component={"div"} variant={"body2"}>
        <Box fontWeight={600} color={"#414042"}>
          {days[dayId || 0].toUpperCase()}
        </Box>
      </Typography>
      {(copied || pasted) ? (
        <Typography variant={"body2"} className={classes.label}>
          <Check className={classes.checkIcon} />
          {copied && t("button.copied")}
          {pasted && t("button.pasted")}
        </Typography>
      ) : (
        <Button
          size={"small"}
          className={clsx(classes.button, {[classes.underline]: allowPaste})}
          onClick={() => {
            // if not open editable row
            if (!open) {
              allowPaste ? paste() : copy();
            }
          }}
          disabled={disabled}
        >
          {allowPaste ? (
            t("button.paste")
          ) : (
            t("button.copy")
          )}
        </Button>
      )}

    </div>
  );
};

export default StyledDay;