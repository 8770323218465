import React from "react";

const Icon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.styleClass}
    >
      <path
        d="M11.4073 15.7007C14.0255 15.7007 16.148 13.6044 16.148 11.0184C16.148 8.43249 14.0255 6.33618 11.4073 6.33618C8.78902 6.33618 6.6665 8.43249 6.6665 11.0184C6.6665 13.6044 8.78902 15.7007 11.4073 15.7007Z"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M17.7931 17.3252L14.7556 14.3252"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default Icon;
