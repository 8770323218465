import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      position: "absolute",
      right: "0",
      bottom: "0",
    },
  })
);
