import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useStyles } from "./styles";
import Link from "@material-ui/core/Link";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Typography } from "@material-ui/core";
import ArrowIcon from "../../assets/icons/arrow.svg";
import { useTranslation } from "react-i18next";
import { buildingType } from "../../types/Devices";
import xss from "xss";
import { boldText } from "../../utils/Common";

interface IProps {
  building: buildingType;
  searchText: string;
}

const SearchBuildingRow = (props: IProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const { building, searchText } = props;
  const { name, devices } = props.building;

  const total = useMemo(() => devices.length || 0, [devices]);
  const online = useMemo(
    () => building?.devices?.filter((device) => device?.State?.Online).length,
    [building]
  );
  const offline = useMemo(() => total - online, [total, online]);
  const problematic = useMemo(
    () => devices.filter((device) => !!device.problem).length || 0,
    [devices]
  );
  const urgent = useMemo(
    () =>
      devices.filter(
        (device) => device.problem && device.problem.level === "urgent"
      ).length || 0,
    [devices]
  );

  const buildingName = useMemo(() => {
    return xss(boldText(name, searchText));
  }, [name, searchText])

  return (
    <TableRow className={classes.row}>
      <TableCell className={classes.col}>
        <div
          className={classes.deviceName}
          dangerouslySetInnerHTML={{__html: buildingName}}
        >
        </div>
      </TableCell>
      <TableCell className={classes.col}>
        <Typography className={classes.title}>
          {t("table_options.total")}
        </Typography>
        {total}
      </TableCell>
      <TableCell className={classes.col}>
        <Typography className={classes.title}>
          {t("table_options.online")}
        </Typography>
        {online}
      </TableCell>
      <TableCell className={classes.col}>
        <Typography className={classes.title}>
          {t("table_options.offline")}
        </Typography>
        {offline}
      </TableCell>
      <TableCell className={classes.col}>
        <Typography className={classes.title}>
          {t("table_options.problematic")}
        </Typography>
        {problematic}
      </TableCell>
      <TableCell
        className={classes.col}
        style={{ color: urgent > 0 ? "#F7204F" : "#414042" }}
      >
        <Typography className={classes.title}>
          {t("table_options.urgent")}
        </Typography>
        {urgent}
      </TableCell>

      <TableCell className={classes.col}>
        <Link
          className={classes.link}
          component={"button"}
          onClick={() => {
            history.push(encodeURI(`/buildings/${building.name}/devices`));
          }}
        >
          <img src={ArrowIcon} alt="" />
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default SearchBuildingRow;
