import React from "react";

const Icon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="stroke"
        d="M12 18.5C15.5898 18.5 18.5 15.5898 18.5 12C18.5 8.41015 15.5898 5.5 12 5.5C8.41015 5.5 5.5 8.41015 5.5 12C5.5 15.5898 8.41015 18.5 12 18.5Z"
        stroke=""
      />
      <path
        className="fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9999 9.5C12.4141 9.5 12.7499 9.16421 12.7499 8.75C12.7499 8.33579 12.4141 8 11.9999 8C11.5857 8 11.2499 8.33579 11.2499 8.75C11.2499 9.16421 11.5857 9.5 11.9999 9.5ZM11 10.25H10.5V11.25H11H11.5V14.25H11H10.5V15.25H11H12H13H13.5V14.25H13H12.5V10.75C12.5 10.4739 12.2761 10.25 12 10.25H11Z"
        fill=""
      />
    </svg>
  );
};
export default Icon;
