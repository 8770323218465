import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { Colors } from "../../constants/Style";
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    button: {
      "&.MuiButtonBase-root": {
        padding: "8px 0 8px 24px",
        minWidth: "initial",
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
      "& .MuiTouchRipple-root": {
        display: "none",
      },
    },
    icon: {
      fill: "#fff",
      transition: "0.2s",
    },
    fixedIcon: {
      fill: "#414042",
      "&:hover": {
        fill: Colors.brand,
      },
    },
    popper: {
      width: "240px",
      "& .MuiPaper-root": {
        background: "#FFFFFF",
        boxShadow:
          "0px 7px 15px -6px rgba(0, 5, 11, 0.08), 0px 1px 3px -1px rgba(12, 41, 76, 0.08)",
        borderRadius: "8px",
        margin: "24px 0 0 0",
      },
      "& .MuiList-root": {
        padding: "0",
      },
    },
    menuItem: {
      "&.MuiMenuItem-root": {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        cursor: "default",
      },
      "&.MuiListItem-button:hover": {
        backgroundColor: "transparent",
      },
      "&:first-of-type": {
        padding: "19px 16px 19px 24px",
        borderBottom: "1px solid #D9DEE3",
      },
      "&:last-of-type": {
        padding: "0 0 0 24px",
      },
      "& .MuiTouchRipple-root": {
        display: "none",
      },
      "& button": {
        display: "flex",
        padding: "19px 24px",
      },
    },
  })
);
