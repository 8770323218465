import React from "react";

const Pattern = (props) => {
  return (
    <svg
      width="520"
      height="520"
      viewBox="0 0 520 520"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M610.52 355.46L576.77 321.72H483.65L563.76 402.22L483.65 482.33H644.27V321.72L610.52 355.46Z"
        fill="#3C3D98"
      />
      <path
        d="M610.51 194.68L576.77 160.93H483.65L563.75 241.44L483.65 321.55H644.26V160.93L610.51 194.68Z"
        fill="#3C3D98"
      />
      <path
        d="M610.61 33.75L576.86 0H483.74L563.85 80.51L483.74 160.62H644.36V0L610.61 33.75Z"
        fill="#3C3D98"
      />
      <path
        d="M449.59 515.69L415.84 481.94H322.72L402.83 562.45L322.72 642.56H483.33V481.94L449.59 515.69Z"
        fill="#3C3D98"
      />
      <path
        d="M449.58 355.46L415.83 321.72H322.71L402.82 402.22L322.71 482.33H483.33V321.72L449.58 355.46Z"
        fill="#3C3D98"
      />
      <path
        d="M449.68 194.68L415.93 160.93H322.81L402.92 241.44L322.81 321.55H483.42V160.93L449.68 194.68Z"
        fill="#3C3D98"
      />
      <path
        d="M288.65 515.69L254.9 481.94H161.78L241.89 562.45L161.78 642.56H322.4V481.94L288.65 515.69Z"
        fill="#3C3D98"
      />
      <path
        d="M288.64 355.46L254.9 321.72H161.78L241.89 402.22L161.78 482.33H322.39V321.72L288.64 355.46Z"
        fill="#3C3D98"
      />
      <path
        d="M127.72 515.69L93.97 481.94H0.849976L80.96 562.45L0.849976 642.56H161.46V481.94L127.72 515.69Z"
        fill="#3C3D98"
      />
    </svg>
  );
};
export default Pattern;
