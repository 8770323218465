import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme, Typography } from "@material-ui/core";
import { formatHour } from "../../../../utils/Common";
import { ReactComponent as Triangle } from "../../../../assets/icons/triangle.svg";
import { ReactComponent as Info } from "../../../../assets/icons/info.svg";
import clsx from "clsx";
import Tooltip from "@material-ui/core/Tooltip";
import { useTranslation } from "react-i18next";
import withStyles from "@material-ui/core/styles/withStyles";
import { Colors } from "../../../../constants/Style";

type Props = {
  count: number;
};

const useStyles = makeStyles<Theme, Props>(theme => ({
  root: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: props => `${42.66 * props.count || 1}px`,
    height: "56px",
    background: "linear-gradient(90deg, #7AA8EE 0%, #7AA8EE 63.65%, #73B6BF 100%)",
    border: "1px solid #FFFFFF",
    color: "#FFFFFF",
    "&:first-of-type": {
      borderRadius: "4px 0px 0px 4px",
    },
  },
  triangle: {
    position: "absolute",
    bottom: 0,
    marginLeft: props => `${(42.66 / 2 * props.count || 1) - 6}px`,
  },
  info: {
    position: "absolute",
    top: "16px",
    marginLeft: "-32px",
    width: "24px",
    height: "24px",
  },
  subtitle: {
    marginTop: 4,
    color: "#FFFFFF",
  },
  miniSubtitle: {
    fontSize: "7px",
    lineHeight: "8px",
    marginTop: 7,
  },
  hidden: {
    display: "none",
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#FFFFFF',
    color: Colors.dark,
    maxWidth: 368,
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #F6F8FA',
    borderRadius: "16px",
    boxShadow: "0px 8px 19px -15px rgba(17, 41, 104, 0.3)",
    padding: "25px 40px 24px 30px"
  },
}))(Tooltip);


interface iProps {
  value: number;
  hour: number;
  day: number;
  count: number;
  open?: boolean;
  onClick?: () => void;
  onDragStart: (pos: number) => void;
  style?: {
    background?: string;
  };
  arrowStyle?: {
    color?: string;
  }
}

const ScheduleBox = (props: iProps) => {
  const {
    value, hour, day, count, open = false, onClick, onDragStart, style = {}, arrowStyle = {}
  } = props;

  const {t} = useTranslation();

  const classes = useStyles({ count });
  if (count === -1) {
    return null;
  }
  return (
    <div
      id={`${day}-${hour}`}
      className={classes.root}
      onClick={onClick}
      draggable={true}
      onDragStart={event => {
        const el = document.getElementById(`${day}-${hour}`);
        const left = el!.getBoundingClientRect().left;
        const width = el!.offsetWidth;
        const offset = event.pageX;
        const pos = Math.trunc((offset - left) / (width / count));
        console.log("event.movementX", pos);
        onDragStart(pos);
      }}
      {...style && {style: style}}
    >
      {hour > 0 && (
        <HtmlTooltip
          title={
            <React.Fragment>
              {t("info.schedule")}
            </React.Fragment>
          }
        >
          <Info className={clsx(classes.info, { [classes.hidden]: count < 3 })} />
        </HtmlTooltip>
      )}
      <Triangle
        className={
          clsx(classes.triangle, {
            [classes.hidden]: !open
          })}
        {...arrowStyle && {style: arrowStyle}}
      />
      <Typography variant={"body2"} align={"center"}>
        {value} °C
      </Typography>
      <Typography variant={"subtitle2"} align={"center"} className={classes.subtitle}>
        {count > 1 ? (
          `${formatHour(hour)} - ${formatHour(hour + count)}`
        ) : (
          formatHour(hour)
        )}
      </Typography>
    </div>
  );
};

export default ScheduleBox;