import useSWR from "swr";
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { fetcher, useQuery } from "../utils/Common";
import { Endpoints } from "../api/endpoints";
import { useAuth } from "./Auth";
import { useParams } from "react-router-dom";
import {
  buildingType,
  devicesFilterType,
  devicesPaginationType,
  deviceType,
  UseDevices,
} from "../types/Devices";
import {
  apiDeviceChangeTimer,
  apiDeviceGetLimitTemperature,
  apiDeviceHistoryGet1Day,
} from "../api/device";
import { getDeviceProblem } from "../utils/Devices";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";
import { useLang } from "./Lang";
import { apiGetTemperatureSchedules } from "../api/schedule";
import { scheduleInterface } from "../types/Schedule";
import { uniqBy } from "lodash";

const initialDevicesValues = {};

const devicesContext = createContext(initialDevicesValues);

export const useProvideDevices = (): UseDevices => {
  const query = useQuery();

  const { lang } = useLang();

  const { t } = useTranslation();

  const { buildingName: bName } = useParams<{ buildingName: string }>();
  const { groupName: gName } = useParams<{ groupName: string }>();

  const [buildingName, setBuildingName] = useState<string>(bName);
  const [groupName, setGroupName] = useState<string>(gName);

  const [searchText, setSearchText] = useState<string>("");
  const [searchDevices, setSearchDevices] = useState<deviceType[]>([]);

  const localStorageDevices = JSON.parse(sessionStorage.getItem("themo-devices") || "[]");

  const [devices, setDevices] = useState<deviceType[]>(localStorageDevices || []);

  const [devicesCopy, setDevicesCopy] = useState<deviceType[]>([]);

  const [selectedDevices, setSelectedDevices] = useState<deviceType[] | []>([]);
  const [addedAdditionalData, setAddedAdditionalData] =
    useState<boolean>(false);

  const [devicePagination, setDevicePagination] =
    useState<devicesPaginationType>({
      totalPages: 0,
      prev: false,
      next: false,
    });

  const [newDeviceGroupName, setNewDeviceGroupName] = useState("");

  const [filter, setFilter] = useState<devicesFilterType>({
    mode: "",
    lockOption: "",
    temperatureFrom: undefined,
    temperatureTo: undefined,
    lock: false,
    frost: false,
    hideProblematic: false,
  });

  const resetFilter = useCallback(() => {
    setFilter({
      mode: "",
      lockOption: "",
      temperatureFrom: undefined,
      temperatureTo: undefined,
      lock: false,
      frost: false,
      hideProblematic: false,
    });
  }, []);

  const { userId, token, isLoggedIn } = useAuth();

  const initialPage = useMemo(
    () => parseInt(query?.get("page") ?? "0"),
    [query],
  );

  const [page, setPage] = useState<number>(initialPage);
  const [pageSize, setPageSize] = useState<number>(-1);
  const [getDeviceState, setGetDeviceState] = useState<boolean>(true);

  const [localPage, setLocalPage] = useState<number>(1);
  const [localPageSize, setLocalPageSize] = useState<number>(10);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!isLoggedIn) {
      setDevices([]);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (addedAdditionalData) {
      setAddedAdditionalData(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize]);

  const url = useMemo(
    () =>
      `${Endpoints.devices}?clientID=${userId}&state=${getDeviceState}&page=${page}&pageSize=${pageSize}&api-version=2.0`,
    [page, pageSize, getDeviceState, userId],
  );

  const {
    data: devicesData,
    error: devicesDataError,
    mutate: mutateDevices,
  } = useSWR(
    (userId && token && isLoggedIn) ? [url, token] : null,
    fetcher,
    // { refreshInterval: 10000 },
  );

  const [
    firstDeviceLast24AverageOutsideTemperature,
    setFirstDeviceLast24AverageOutsideTemperature,
  ] = useState<number>(Number(sessionStorage.getItem("themo-firstDeviceLast24AverageOutsideTemperature")));

  const addLimitTemperatureFunction = useCallback(async () => {
    if (devices && devices.length > 0) {
      setIsLoading(true);
      let _devices = devices;
      await Promise.all(
        _devices.map(async (device, key) => {
          if (device.State?.Mode === "SLS") {
            if (device.limitTemperature === undefined) {
              await apiDeviceGetLimitTemperature({
                deviceId: device.ID,
                token,
              })
                .then((resp) => {
                  let { data } = resp;
                  if (data.Value) {
                    _devices[key].limitTemperature = data.Value;
                  }
                })
                .catch(() => {
                  _devices[key].limitTemperature = -1;
                });
            }
          }
          _devices[key].problem = getDeviceProblem({
            device: _devices[key],
            t,
          });
          setDevices([..._devices]);
        }),
      );
      setIsLoading(false);
    }
  }, [devices, token, t]);

  useEffect(() => {
    addLimitTemperatureFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices?.length]);

  const addAdditionalDataFunction = useCallback(async () => {
    if (!addedAdditionalData && devices && devices.length > 0) {
      setIsLoading(true);
      setAddedAdditionalData(true);
      let _devices = devices;
      let addedDevice1DayHistory: boolean = false;

      await Promise.all(
        _devices.map(async (device, key) => {
          if (device.State?.Mode === "SLS") {
            if (device.limitTemperature === undefined) {
              await apiDeviceGetLimitTemperature({
                deviceId: device.ID,
                token,
              })
                .then((resp) => {
                  let { data } = resp;
                  if (data.Value) {
                    _devices[key].limitTemperature = data.Value;
                  }
                })
                .catch(() => {
                  _devices[key].limitTemperature = -1;
                });
            }
            if (!(device.last24Consumption !== undefined || device.lastDatapointDate)) {
              await apiDeviceHistoryGet1Day({
                deviceId: device.ID,
                token,
              })
                .then((resp) => {
                  let last24consumption = 0;
                  resp.data[0].TimeSeries.forEach((consumption: any) => {
                    last24consumption =
                      last24consumption +
                      consumption.Values[consumption.Values.length - 1];
                  });
                  _devices[key].last24Consumption = last24consumption;
                  _devices[key].lastDatapointDate = resp.data[0].TimeSeries[23];
                  if (!addedDevice1DayHistory) {
                    let last24OutsideTemperature = 0;
                    resp.data[0].TimeSeries.forEach((consumption: any) => {
                      last24OutsideTemperature =
                        last24OutsideTemperature + consumption.Values[2];
                    });
                    const _outsideTemp = last24OutsideTemperature / resp.data[0].TimeSeries.length || 1;
                    setFirstDeviceLast24AverageOutsideTemperature(
                      _outsideTemp,
                    );
                    sessionStorage.setItem("themo-firstDeviceLast24AverageOutsideTemperature", _outsideTemp.toString());
                    addedDevice1DayHistory = true;
                  }
                })
                .catch(() => {
                  _devices[key].last24Consumption = -1;
                });
            }
          }
          _devices[key].problem = getDeviceProblem({
            device: _devices[key],
            t,
          });
          setDevices([..._devices]);
        }),
      );

      //add _devices to sessionStorage after all data is added
      sessionStorage.setItem("themo-devices", JSON.stringify(_devices));

      setIsLoading(false);
      // setDevices([..._devices]);

    }
  }, [devices, addedAdditionalData, t, token]);

  // // add additional data to devices
  // useEffect(() => {
  //   addAdditionalDataFunction();
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [devices, addedAdditionalData]);

  // useEffect(() => {
  //   if (addedAdditionalData) {
  //     setAddedAdditionalData(false);
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [lang]);

  const getBuildings = useCallback((devices: deviceType[]): buildingType[] => {
    const names: string[] = [];
    devices.forEach((device: any) => {
      const splits = device.DeviceName?.split("-");
      let name: string | undefined = undefined;
      if (splits.length > 1) {
        name = splits[0];
      }
      if (name && !names.includes(name)) {
        names.push(name);
      }
    });
    return names.map((name) => ({
      name: name,
      devices: devices.filter((device: any) => {
        const splits = device.DeviceName?.split("-");
        let devName: string | undefined = undefined;
        if (splits.length > 1) {
          devName = splits[0];
        }
        return devName && name === devName;
      }),
    }));
  }, []);

  const getDeviceGroupsByEnvironmentName = useCallback((devices: deviceType[]): buildingType[] => {
    const names: string[] = [];
    devices.forEach((device: any) => {
      const name = device.EnvironmentName;
      if (name && name !== 'Home' && !names.includes(name)) {
        names.push(name);
      }
    });
    return names.map((name) => ({
      name: name,
      devices: devices.filter((device: any) => {
        const devName = device.EnvironmentName;
        return name === devName;
      }),
    }));
  }, []);

  const buildings = useMemo(() => {
    return getBuildings(devicesCopy);
  }, [devicesCopy, getBuildings]);

  const deviceGroups = useMemo(() => {
    return getDeviceGroupsByEnvironmentName(devices);
  }, [devices, getDeviceGroupsByEnvironmentName]);

  const orderAndFilterDevices = useCallback(() => {
    if (devices) {
      let devs = devices;

      devs = orderBy(devs, ["problem", "problem.level"], ["asc", "desc"]);

      devs = devs.filter((device: any) => {
        let toShow = true;
        if (
          filter.temperatureFrom &&
          filter.temperatureFrom > device?.State.Info
        ) {
          toShow = false;
        }
        if (filter.temperatureTo && filter.temperatureTo < device?.State.Info) {
          toShow = false;
        }
        if (filter.lock && !device?.State.Lock) {
          toShow = false;
        }
        if (filter.frost && !device?.State.FTS) {
          toShow = false;
        }
        if (filter.hideProblematic && !!device.problem) {
          toShow = false;
        }
        if (filter.mode && device.State.Mode !== filter.mode) {
          toShow = false;
        }
        if (filter.lockOption) {
          if (filter.lockOption === "Off" && !!device.State.Lock) {
            toShow = false;
          }
          if (filter.lockOption === "Child" && !device.State.Lock) {
            toShow = false;
          }
        }
        return toShow;
      });

      return devs;
    }
    return [];
  }, [devices, filter]);

  useEffect(() => {
    if (searchText && devices) {
      let devs = orderAndFilterDevices();

      const exactResults = devs.filter(
        (device: deviceType) =>
          device.DeviceName.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ||
          device.EnvironmentName.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()),
      );

      if (exactResults) {
        setSearchDevices(exactResults);
      } else {
        setSearchDevices(
          devs.filter((device: deviceType) => {
            const words = searchText.split(" ");
            let ok = false;
            words.forEach((word, key) => {
              if (
                word &&
                word.length > 0 &&
                isNaN(Number(word))
                  ?
                  device.DeviceName.toLowerCase().includes(word.toLowerCase())
                  :
                  key > 0
                    ?
                    device.DeviceName.toLowerCase().includes(words[key - 1] + word.toLowerCase())
                    :
                    words[key + 1]
                      ?
                      device.DeviceName.toLowerCase().includes(word.toLowerCase() + words[key + 1])
                      :
                      device.DeviceName.toLowerCase().includes(word.toLowerCase())
              ) {
                ok = true;
              }
            });
            return ok;
          }),
        );
      }
    }
  }, [devices, searchText, filter, orderAndFilterDevices]);

  const searchBuildings = useMemo(
    () => getBuildings(searchDevices),
    [searchDevices, getBuildings],
  );

  useEffect(() => {
    if (!!devicesData?.Devices) {
      if (isEmpty(devices)) {
        setDevices(devicesData.Devices);
      } else {
        let new_devs = devicesData.Devices;

        new_devs.forEach((device: deviceType, key: number) => {
          const localDeviceIndex = devices.findIndex(dev => dev.ID === device.ID);
          if (localDeviceIndex !== -1) {
            new_devs[key] = {
              ...devices[localDeviceIndex],
              ...device,
            };
          }
        });
        setDevices(new_devs);
      }

      setAddedAdditionalData(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devicesData?.Devices]);

  useEffect(() => {
    if (devices && devices.length > 0) {
      let devs = orderAndFilterDevices();

      if (buildingName || groupName) {
        setDevicesCopy(
          devs.filter((device: any) => {
            const splits = device.DeviceName?.split("-");
            let devName: string = "";
            if (buildingName && splits.length > 1) {
              devName = splits[0];
              return buildingName === devName;
            } else if (groupName) {
              devName = device.EnvironmentName;
              return groupName === devName;
            }
            return false;
          }),
        );
      } else {
        setDevicesCopy(devs);
      }
    }
  }, [devices, filter, buildingName, groupName, orderAndFilterDevices]);

  useEffect(() => {
    if (!!devicesData?.TotalPages) {
      setDevicePagination({
        totalPages: devicesData?.TotalPages,
        prev: devicesData?.Prev,
        next: devicesData?.Next,
      });
    }
  }, [devicesData?.Next, devicesData?.Prev, devicesData?.TotalPages]);

  const totalPower = useMemo(() => {
    let power = 0;
    devices &&
    devices.forEach((device: any) => {
      power = power + device.State?.MP || 0;
    });
    return power;
  }, [devices]);

  const total24Consumption = useMemo(() => {
    let consum = 0;
    devices && devices.forEach((device) => {
      consum = consum + (device.last24Consumption && device.last24Consumption > 0 ? device.last24Consumption : 0);
    });
    return consum;
  }, [devices]);

  const heating24Hours = useMemo(
    () => ((total24Consumption || 0) * 100) / (totalPower * 24),
    [totalPower, total24Consumption],
  );

  const loading = useMemo(
    () => (!devicesData && !devicesDataError) || isLoading,
    [devicesData, devicesDataError, isLoading],
  );

  const refreshDevicesData = useCallback((updatedDevice?: deviceType) => {
    if (updatedDevice) {
      let _devices = devices;
      const deviceIndex = _devices.findIndex((device) => device.ID === updatedDevice.ID);
      if (deviceIndex !== -1) {
        _devices[deviceIndex] = updatedDevice;
        setDevices([..._devices]);
      }
      return;
    }
    if (!loading) {
      setIsLoading(true);
      setTimeout(() => {
        mutateDevices().then(() => setIsLoading(false));
      }, 2000);
    }
  }, [loading, devices, mutateDevices]);

  const hardRefreshDevicesData = useCallback((event: any) => {
    event.preventDefault();
    if (!loading) {
      console.log("perform hard refresh all devices");
      setDevices([]);
      sessionStorage.removeItem("themo-devices");
      refreshDevicesData();
    }
  }, [refreshDevicesData, loading]);

  const devicesCount = useMemo(() => devicesCopy.length || 0, [devicesCopy.length]);

  const [devicesSchedules, setDevicesSchedules] = useState<scheduleInterface[]>([]);

  const getDevicesSchedules = useCallback(async (_selectedDevices = selectedDevices) => {
    if (loading || !_selectedDevices || _selectedDevices.length === 0) {
      return []
    }
    setIsLoading(true);
    let _schedules: scheduleInterface[] = []

    for (const device of _selectedDevices) {
      await apiGetTemperatureSchedules({
        deviceId: device.ID,
        token,
      }).then(({data: schedules}) => {
        if (schedules && schedules.length > 0) {
          _schedules.push(...schedules)
        }
      })
    }
    /*
    Verify if all devices have the same Active schedule, if not -> remove the Active attribute from all of them
     */
    const activeSchedules = _schedules.filter((sch) => sch.Active);
    const condition = (el: scheduleInterface) => el.Name !== activeSchedules[0].Name;

    if (activeSchedules.length !== selectedDevices.length || activeSchedules.some(condition)) {
      _schedules = _schedules.map((sch) => ({ ...sch, Active: false }))
    }
    // Remove duplicates by Name
    _schedules = uniqBy(_schedules, 'Name')
    setIsLoading(false);
    setDevicesSchedules(_schedules);
    return _schedules;
  }, [selectedDevices, token, loading]);



  useEffect(() => {
    if (selectedDevices.length === 0) {
      setDevicesSchedules([]);
    }
  }, [selectedDevices]);

  const changeTimer = useCallback((device: deviceType, value: number) => {
    return apiDeviceChangeTimer({
      deviceId: device.ID,
      value,
      token,
    });
  }, [token]);

  return {
    searchText,
    searchBuildings,
    searchDevices,
    setSearchText,
    selectedDevices, setSelectedDevices,
    buildingName, setBuildingName,
    groupName, setGroupName,
    buildings,
    deviceGroups,
    newDeviceGroupName, setNewDeviceGroupName,
    devices: devicesCopy,
    devicePagination,
    loading,
    page, setPage,
    localPage,
    localPageSize,
    setLocalPage,
    setGetDeviceState,
    setPageSize,
    setLocalPageSize,
    filter,
    setFilter,
    resetFilter,
    heating24Hours,
    firstDeviceLast24AverageOutsideTemperature,
    totalPower,
    refreshDevicesData,
    hardRefreshDevicesData,
    devicesCount,
    getDevicesSchedules,
    devicesSchedules,
    addAdditionalDataFunction,
    changeTimer,
  };
};

export const DevicesProvider = ({ children }: PropsWithChildren<{}>) => {
  const devices = useProvideDevices();
  return (
    <devicesContext.Provider value={devices}>
      {children}
    </devicesContext.Provider>
  );
};

export const useDevices = (): UseDevices => {
  return useContext(devicesContext) as UseDevices;
};
