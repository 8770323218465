import hover from "./hover.svg";
import React, { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
import { ReactComponent as Triangle } from "../../../../assets/icons/triangle.svg";

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    width: "42.66px",
    height: "56px",
    border: "1px solid #FFFFFF",
    backgroundColor: "#EEF1F4",
    cursor: "pointer",
    "& :hover, :focus, :active, :drop": {
      border: "1px solid red",
    },
    "&::[drop-active=true]": {
      boxShadow: "inset 0px 0px 0px 2px #00C",
    },
  },
  hover: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    backgroundImage: `url(${hover})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    transition: "opacity .2s ease-in",
    opacity: 0,
  },
  display: {
    opacity: 1,
  },
  hidden: {
    display: "none",
  },
  triangle: {
    position: "absolute",
    bottom: -7,
    marginLeft: `${(42.66 / 2) - 6}px`,
    "& path": {
      fill: "#EEF1F4",
    },
  },
}));
const Cell = ({ hour, onClick, onDrop, open = false, children }: { hour: number, open?: boolean, onClick: () => void, onDrop: () => void, children?: React.ReactNode }) => {
  const [hover, setHover] = useState<boolean>(false);
  const classes = useStyles();
  return (
    <div
      className={classes.root}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onDragOver={(event) => {
        event.preventDefault();
        setHover(true);
      }}
      onDragLeave={() => {
        setHover(false);
      }}
      onDrop={(event) => {
        console.log("onDrop", hour);
        onDrop();
      }}
      onClick={onClick}
    >
      <Triangle className={clsx(classes.triangle, { [classes.hidden]: !open })} />
      <div className={clsx(classes.hover, { [classes.display]: hover || open })} />
      {children}
    </div>
  );
};

export default Cell;