import { createTheme, ThemeOptions } from "@material-ui/core/styles";
import AvertaRegular from "../../src/assets/fonts/AvertaStd-Regular.woff2";
import AvertaBold from "../../src/assets/fonts/AvertaStd-Bold.woff2";
import RobotoRegular from "../../src/assets/fonts/roboto-regular.woff2";
import RobotoMedium from "../../src/assets/fonts/roboto-medium.woff2";
import RobotoBold from "../../src/assets/fonts/roboto-bold.woff2";

const averta = [
  {
    fontFamily: "Averta Std",
    fontWeight: 400,
    src: `url(${AvertaRegular}) format('woff2')`,
  },
  {
    fontFamily: "Averta Std",
    fontWeight: 700,
    src: `url(${AvertaBold}) format('woff2')`,
  },
  {
    fontFamily: "Roboto",
    fontWeight: 400,
    src: `url(${RobotoRegular}) format('woff2')`,
  },
  {
    fontFamily: "Roboto",
    fontWeight: 500,
    src: `url(${RobotoMedium}) format('woff2')`,
  },
  {
    fontFamily: "Roboto",
    fontWeight: 700,
    src: `url(${RobotoBold}) format('woff2')`,
  },
];

export default createTheme({
  palette: {},
  typography: {
    fontFamily: "Roboto",
    h1: {
      fontFamily: "Averta Std",
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: 700,
    },
    h2: {
      fontFamily: "Averta Std",
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: 700,
    },
    h3: {
      fontFamily: "Averta Std",
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: 700,
    },
    h4: {
      fontSize: "20px",
      lineHeight: "32px",
    },
    body1: {
      fontSize: "16px",
    },
    body2: {
      fontSize: "14px",
      lineHeight: "16px",
    },
    subtitle2: {
      fontSize: "10px",
      lineHeight: "12px",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": averta,
        body: {
          fontFamily: "Roboto",
          // fontFamily: "Roboto",
          backgroundColor: "#F6F8FA",
        },
        a: {
          textDecoration: "none",
        },
      },
    },
  },
}) as ThemeOptions;
