import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { Colors } from "../../constants/Style";
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      "& .MuiBackdrop-root": {
        background: "rgba(0,0,0, 0.85)",
      },
      "& .MuiDialog-paperWidthSm": {
        borderRadius: "40px",
      },
    },
    modalBody: {
      position: "relative",
      background: "#FFFFFF",
      borderRadius: "40px",
      border: "none",
      width: "592px",
      "&.MuiDialogContent-root": {
        padding: "48px 56px 40px",
      },
    },
    closeBtn: {
      position: "absolute",
      top: "22px",
      right: "22px",
      padding: "10px",
      "&:hover svg path": {
        stroke: Colors.brand,
      },
      "& svg": {
        width: "24px",
        height: "24px",
        "& path": {
          transition: "0.2s",
        },
      },
    },
    modalHeader: {
      margin: "0 0 36px 0",
      "& h2": {
        color: "#414042",
        margin: "0 0 5px 0",
      },
    },
    hint: {
      fontSize: "12px",
      lineHeight: "16px",
      color: "#85868B",
      "&:nth-of-type(2)": {
        margin: "0 0 16px 0",
      },
      "& a": {
        textDecoration: "none",
        color: Colors.brand,
        margin: "0 0 0 3px",
      },
    },
    accordion: {
      boxShadow: "none",
      borderRadius: "0",
      borderTop: "1px solid #D9DEE3",
      "&:last-of-type": {
        borderBottom: "1px solid #D9DEE3",
      },
      "&.Mui-expanded": {
        margin: "0",
        "&:before": {
          opacity: "1",
        },
      },
      "&:before": {
        content: "none",
      },
      "& .MuiFormControl-root": {
        marginTop: 16,
        marginBottom: 10,
      }
    },
    accordionSummary: {
      fontFamily: "Roboto",
      fontSize: "14px",
      lineHeight: "16px",
      color: "#414042",
      padding: "24px 16px 24px 0",
      transition: "0.2s",
      minHeight: "initial",
      "&:hover": {
        color: Colors.brand,
      },
      "& .MuiAccordionSummary-content": {
        margin: "0",
        fontWeight: "500",
      },
      "& .MuiButtonBase-root": {
        padding: "0",
        "& svg": {
          fill: Colors.brand,
        },
      },
    },
    accordionDetails: {
      flexDirection: "column",
      fontFamily: "Roboto",
      fontSize: "14px",
      lineHeight: "20px",
      color: "#414042",
      padding: "0 0 40px 0",
      "& button": {
        display: "block",
        background: Colors.brand,
        boxShadow:
          "0px 16px 13px -15px rgba(83, 75, 158, 0.3), 0px 8px 22px -8px rgba(92, 79, 161, 0.45)",
        borderRadius: "20px",
        fontFamily: "Roboto",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "16px",
        color: " #FFFFFF",
        margin: "26px auto 0",
        padding: "12px 18px",
        transition: "0.2s",
        minWidth: "144px",
        textAlign: "center",
        "&:hover": {
          background: "#3B3E96",
        },
      },
      "& textarea": {
        // border: "1px solid #D9DEE3",
        // borderRadius: "8px",
        // padding: "16px 23px",
        // margin: "10px 0 6px",
        // minHeight: "96px",
        // transition: "0.2s",
        // "&:hover, &:focus": {
        //   border: "1px solid #7C64AC",
        // },
      },
    },
    solutionLabel: {
      color: "#414042",
      lineHeight: "20px",
      "&:last-of-type": {
        marginTop: 20,
      },
    },
    textField: {
      "& .MuiOutlinedInput-multiline": {
        padding: "16px 23px",
      },
      "& fieldset": {
        border: "1px solid #D9DEE3",
        borderRadius: "8px",
      }
    },
    button: {
      textTransform: "none",
      display: "block",
      background: Colors.brand,
      boxShadow:
        "0px 16px 13px -15px rgba(83, 75, 158, 0.3), 0px 8px 22px -8px rgba(92, 79, 161, 0.45)",
      borderRadius: "20px",
      fontFamily: "Roboto",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "16px",
      color: " #FFFFFF",
      margin: "26px auto 0",
      padding: "12px 18px",
      transition: "0.2s",
      minWidth: "144px",
      textAlign: "center",
      "&:hover": {
        background: "#3B3E96",
      },
    }
  })
);
