import React, { useCallback, useContext, useState } from "react";
import { deviceType } from "../../types/Devices";
import AppLinearProgress from "../AppLinearProgress";
import { useAuth } from "../../state/Auth";
//import useScriptChat from "../../hooks/useScriptChat";

type loadingType = {
  scope: string, value: boolean
}

export type GlobalContent = {
  scheduleSwitchMode: boolean,
  setScheduleSwitchMode: (value: boolean) => void,
  scheduleName: string
  setScheduleName: (value: string) => void
  openScheduleModal: boolean
  setOpenScheduleModal: (value: boolean) => void
  openOfflineTemperatureModal: boolean
  setOpenOfflineTemperatureModal: (value: boolean) => void
  openElectricityContractModal: boolean
  setOpenElectricityContractModal: (value: boolean) => void
  openGroupModal: boolean
  setOpenGroupModal: (value: boolean) => void
  newGroup: boolean
  setNewGroup: (value: boolean) => void
  selectedDevices: deviceType[]
  setSelectedDevices: (devices: deviceType[]) => void
  openTroubleshootingModal: boolean
  setOpenTroubleshootingModal: (value: boolean) => void
  troubleshootingDevice?: deviceType
  setTroubleshootingDevice: (value: deviceType | undefined) => void
  isLoading: loadingType[]
  setIsLoading: (val: loadingType[]) => void
  handleSetIsLoading: (val: loadingType) => void
}

export const AppContext = React.createContext<GlobalContent>({
  scheduleSwitchMode: false,
  setScheduleSwitchMode: () => null,
  scheduleName: "",
  setScheduleName: () => null,
  openScheduleModal: false,
  setOpenScheduleModal: () => null,
  openOfflineTemperatureModal: false,
  setOpenOfflineTemperatureModal: () => null,
  openElectricityContractModal: false,
  setOpenElectricityContractModal: () => null,
  openGroupModal: false,
  setOpenGroupModal: () => null,
  newGroup: false,
  setNewGroup: () => null,
  selectedDevices: [],
  setSelectedDevices: () => null,
  openTroubleshootingModal: false,
  setOpenTroubleshootingModal: () => null,
  troubleshootingDevice: undefined,
  setTroubleshootingDevice: () => null,
  isLoading: [],
  setIsLoading: () => null,
  handleSetIsLoading: () => null,
});

export const AppProvider = ({ children }: { children: React.ReactNode }) => {

  const { isLoggedIn /*user*/ } = useAuth();

  const [scheduleSwitchMode, setScheduleSwitchMode] = useState(false);
  const [scheduleName, setScheduleName] = useState<string>("");
  const [openScheduleModal, setOpenScheduleModal] = useState<boolean>(false);
  const [openOfflineTemperatureModal, setOpenOfflineTemperatureModal] = useState<boolean>(false);
  const [openTroubleshootingModal, setOpenTroubleshootingModal] = useState<boolean>(false);
  const [openElectricityContractModal, setOpenElectricityContractModal] = useState<boolean>(false);
  const [openGroupModal, setOpenGroupModal] = useState<boolean>(false);
  const [newGroup, setNewGroup] = useState<boolean>(false);
  const [selectedDevices, setSelectedDevices] = useState<deviceType[]>([]);
  const [troubleshootingDevice, setTroubleshootingDevice] = useState<deviceType>();
  const [isLoading, setIsLoading] = useState<loadingType[]>([]);

  const handleSetIsLoading = useCallback((val: loadingType) => {
    const index = isLoading && isLoading.findIndex(loading => loading.scope === val.scope);

    if (isLoading && index !== -1) {
      let _isLoading = isLoading;
      _isLoading[index].value = val.value;
      setIsLoading([..._isLoading]);
    } else {
      setIsLoading([
        ...isLoading,
        val,
      ]);
    }
  }, [isLoading]);

  //add live chat hook
  //useScriptChat(user);

  return (
    <AppContext.Provider value={{
      scheduleSwitchMode, setScheduleSwitchMode,
      scheduleName, setScheduleName,
      openScheduleModal, setOpenScheduleModal,
      openOfflineTemperatureModal, setOpenOfflineTemperatureModal,
      openElectricityContractModal, setOpenElectricityContractModal,
      openGroupModal, setOpenGroupModal,
      newGroup, setNewGroup,
      selectedDevices, setSelectedDevices,
      openTroubleshootingModal, setOpenTroubleshootingModal,
      troubleshootingDevice, setTroubleshootingDevice,
      isLoading, setIsLoading,
      handleSetIsLoading,
    }}>
      {isLoggedIn && isLoading.find(loading => loading.value) && (
        <AppLinearProgress />
      )}
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => {
  return useContext(AppContext);
};