import React, { useCallback, useEffect, useMemo, useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import TroubleshootingMsg from "../TroubleshootingMsg";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
import CloseIcon from "../../assets/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { useApp } from "../../views/App/AppProvider";
import { apiSendEmail } from "../../api/emails";
import { useAuth } from "../../state/Auth";

interface IProps {
}

const TroubleshootingModal = (props: IProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    setOpenTroubleshootingModal,
    setTroubleshootingDevice,
    troubleshootingDevice: device,
    openTroubleshootingModal: open,
  } = useApp();

  const { user } = useAuth();

  const problem = device?.problem;

  const handleModal = useCallback(() => {
    setOpenTroubleshootingModal(false);
    setTroubleshootingDevice(undefined);
  }, [setOpenTroubleshootingModal, setTroubleshootingDevice]);

  const [inputText, setInputText] = useState<string>("");
  const [wasSent, setWasSent] = useState<boolean>(false);
  const [openAccordion, setOpenAccordion] = useState<number>();

  useEffect(() => {
    setInputText("");
  }, [openAccordion]);

  useEffect(() => {
    setInputText("");
    setWasSent(false);
    setOpenAccordion(undefined);
  }, [open]);

  const handleChangeText = useCallback((event: any) => setInputText(event.target.value), []);

  const items = useMemo(() => [
    {
      title: t("troubleshooting.room_is_to_hot_or_cold"),
      description: t("troubleshooting.describe_problem"),
    },
    {
      title: t("troubleshooting.i_think_device_is_showing_wrong_temperature"),
      description: t("troubleshooting.describe_problem"),
    },
  ], [t]);

  const handleSend = useCallback(() => {
    if (device && inputText && inputText.length > 0 && openAccordion !== undefined) {
      const message = `
      Title: ${items[openAccordion].title}. \n 
      Message: ${inputText}.
      `;

      apiSendEmail(device.DeviceID, message || "", `${user?.FirstName} ${user?.LastName}`).then((resp) => {
        setWasSent(true);
      });
    }
  }, [device, inputText, openAccordion, items, user?.FirstName, user?.LastName]);

  const urgentAccordion = useMemo(() => items.map((item, key) => (
      <Accordion
        key={key}
        className={classes.accordion}
        expanded={openAccordion === key}
        onChange={() => {
          if (openAccordion === key) {
            setOpenAccordion(-1);
          } else {
            setOpenAccordion(key);
          }
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.accordionSummary}
        >
          {item.title || ""}
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Typography variant={"body2"} className={classes.solutionLabel}>
            {item.description || ""}
          </Typography>
          <TextField
            value={inputText}
            onChange={handleChangeText}
            placeholder={t("common.problem")}
            multiline={true}
            variant={"outlined"}
            rows={4}
            maxRows={6}
            className={classes.textField}
          />
          <Button
            className={classes.button}
            onClick={handleSend}
          >
            {t("button.send")}
          </Button>
        </AccordionDetails>
      </Accordion>
    )),
    [items, openAccordion, setOpenAccordion, inputText, handleChangeText, handleSend, classes, t],
  );

  return (
    <Dialog
      open={open}
      onClose={handleModal}
      scroll={"body"}
      className={classes.modal}
    >
      <DialogContent className={classes.modalBody}>
        <button className={classes.closeBtn} onClick={handleModal}>
          <CloseIcon />
        </button>
        <div className={classes.modalHeader}>
          <Typography component="h2" variant="h2">
            {t("troubleshooting.troubleshooting")}
          </Typography>
          <Typography variant={"body2"} className={classes.hint}>
            {t("troubleshooting.find_recommendations_for_solving_problems")}
          </Typography>
          <Typography variant={"body2"} className={classes.hint}>
            {t("troubleshooting.feel_free_to_contact_us")}
            <a href="mailto:">support@themo.io</a>
          </Typography>
          <TroubleshootingMsg
            modal={false}
            type={problem?.level === "urgent" ? "red" : "gray"}
            message={problem?.message || ""}
          />
        </div>

        <div>
          {problem?.level === "urgent" ? (
            wasSent ? (
              <>
                <Typography variant={"body2"} className={classes.solutionLabel}>
                  {t("troubleshooting.your_issue_has_been_sent")}
                </Typography>
                <Typography variant={"body2"} className={classes.solutionLabel}>
                  {t("troubleshooting.while_you_wait_please_take_a_look")}
                </Typography>
                <Button
                  className={classes.button}
                  onClick={() => {
                    window.open(
                      problem?.link || "https://www.google.com/",
                      "_blank",
                    );
                  }}
                >
                  {t("button.see_solutions")}
                </Button>
              </>
            ) : (
              <>
                {urgentAccordion}
              </>
            )
          ) : (
            <>
              <Typography variant={"body2"} className={classes.solutionLabel}>
                {t("troubleshooting.please_take_a_look")}
              </Typography>
              <Button
                className={classes.button}
                onClick={() => {
                  window.open(
                    problem?.link || "https://www.google.com/",
                    "_blank",
                  );
                }}
              >
                {t("button.see_solutions")}
              </Button>
            </>
          )}
        </div>

      </DialogContent>
    </Dialog>
  );
};

export default TroubleshootingModal;
